import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { updateCabinClass } from "../../../../redux/actions";
import { getLabel } from "../../../../utils/functions";
import config from "../../../../config";

require("./style.scss");

const { cabinClasses } = config;
const values = [];
cabinClasses.forEach(value => {
  values.push({ value, label: getLabel(`class.${value}`) });
});

const ComboboxCabinClass = ({ cabinClass, onChange }) => {
  const handleChange = ({ target }) => {
    const { value, label } = values.find(({ value }) => value === target.value);
    onChange({ code: value, name: label });
  };
  return (
    <div
      className="comboboxCabinClass"
      onClick={event => event.stopPropagation()}
    >
      <label>Class</label>
      <select onChange={handleChange} value={cabinClass}>
        {values.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <FontAwesomeIcon icon={faChevronDown} className="icon" />
    </div>
  );
};

export default connect(
  state => ({
    cabinClass: state.flights.searchbox.cabinClass.code
  }),
  dispatch => ({
    onChange: cabinClass => dispatch(updateCabinClass(cabinClass))
  })
)(ComboboxCabinClass);
