import React, { Component } from "react";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";
import {
  updateFlightsFilter,
  toggleFlightsFilterVisible
} from "../../../../redux/actions";
import FilterBox from "../FilterBox/index.jsx";
import { getLabel, resetFlightsFilter } from "../../../../utils/functions";
import cx from "classnames";
require("./style.scss");

class FilterPanel extends Component {
  handleReset = () => {
    const { updateFilterValue, edges, isOneway, activeSearchKey } = this.props;
    resetFlightsFilter(activeSearchKey, updateFilterValue, edges, isOneway);
  };

  render() {
    const { results, visible, onFilterToggle, counts } = this.props;
    if (results.length === 0) return null;
    return (
      <div className={cx("filterPanel")}>
        <div className="bar" onClick={onFilterToggle}>
          <div>
            <FontAwesomeIcon icon={faFilter} />
            {getLabel("filters")} ({counts} {getLabel("results").toLowerCase()})
          </div>
          <FontAwesomeIcon icon={visible ? faChevronDown : faChevronUp} />
        </div>
        <FilterBox type="price" />
        <FilterBox type="stops" />
        <FilterBox type="airlines" />
        <FilterBox type="shops" />
        <FilterBox type="outbound" />
        <FilterBox type="inbound" />
        <div className="filterTitle">
          <div className="resetWrap">
            <div className="link" onClick={this.handleReset}>
              {getLabel("reset.filter")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    results: state.flights.searches[state.flights.activeSearchKey].results,
    visible: state.flights.filterVisible,
    edges: {
      ...cloneDeep(
        state.flights.searches[state.flights.activeSearchKey].filter.edges
      )
    },
    isOneway:
      state.flights.searches[state.flights.activeSearchKey].criteria.isOneway,
    activeSearchKey: state.flights.activeSearchKey
  }),
  dispatch => ({
    onFilterToggle: () => dispatch(toggleFlightsFilterVisible()),
    updateFilterValue: (key, field, value, param = null) =>
      dispatch(updateFlightsFilter(key, field, value, param))
  })
)(FilterPanel);
