import React from "react";
import request from "axios";
import moment from "moment";
import reactStringReplace from "react-string-replace";
import Helmet from "react-helmet";
import config from "../config";
require("moment-duration-format");

const { currencies } = config;

export const getLocalization = () => {
  return {
    language: localStorage.getItem('language') || "EN",
    country: localStorage.getItem('country') || "NL",
    currency: localStorage.getItem('currency') || "EUR"
  };
};

let { language, currency } = getLocalization();
language = language.toLowerCase();
currency = currency.toLowerCase();

let labels;
if (language === "en") {
  labels = require("../static/labels/en.json");
} else if (language === "nl") {
  labels = require("../static/labels/nl.json");
} else if (language === "pl") {
  labels = require("../static/labels/pl.json");
} else if (language === "it") {
  labels = require("../static/labels/it.json");
} else if (language === "de") {
  labels = require("../static/labels/de.json");
}

export const getLabel = (key, values = {}) => {
  if (labels[key]) {
    if (!values) return labels[key];
    let label = labels[key];
    Object.keys(values).forEach(value => {
      const replacement = values[value];
      if (typeof replacement === "object") {
        label = reactStringReplace(label, "${" + value + "}", (match, i) => (
          <span key={match + i}>{replacement}</span>
        ));
      } else {
        label = label.replace("${" + value + "}", replacement);
      }
    });
    return label;
  }
  console.error("MISSING LABEL", key);
  return key;
};

const getApiHost = () => {
  return config.apiEndpoint;
};

const apiInstance = request.create({
  baseURL: getApiHost(),
  timeout: 90000,
  headers: {
    "Content-Type": "application/json",
    "X-Lang": language,
    "X-Currency": currency
  }
});

const TIME_FORMAT = "HH:mm";
const DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

export const formatDate = (str, format) =>
  moment(str, "DD/MM/YYYY").format(format);

export const getTime = (
  str,
  format = TIME_FORMAT,
  inputFormat = DATETIME_FORMAT
) => moment(str, inputFormat).format(format);

export const formatDuration = duration =>
  moment.duration(duration, "minutes").format("h[h] m[m]");

export const formatTravellers = travellers => {
  const { adt, ch, inf } = travellers;
  if (!ch && !inf) return adt + " " + getLabel("adult" + (adt > 1 ? ".s" : ""));
  const getTravellerIcon = (value, type) => (
    <span key={type} className="iconized">
      {value}
      <img
        src={require("../static/images/icons/" + type + ".png")}
        alt={type}
      />
    </span>
  );
  const labels = [];
  labels.push(getTravellerIcon(adt, "adt"));
  if (ch) labels.push(getTravellerIcon(ch, "ch"));
  if (inf) labels.push(getTravellerIcon(inf, "inf"));
  return labels;
};

export const redirect = (path, reload = false) => {
  if (reload) document.location.pathname = path;
  else document.location.hash = path; // browserHistory.push(path); // FIXME
};

export const api = (path, params) => {
  return new Promise((resolve, reject) =>
    apiInstance
      .get(path, { params })
      .then(response =>
        typeof response.data === "object" && response.data.success
          ? resolve(response.data.data)
          : reject(response.data.message)
      )
      .catch(response => reject(response))
  );
};

const getMatchedAirport = code =>
  api("/search/autocomplete/", { phrase: code }).then(res => {
    if (res.length > 0)
      return Object.assign(res[0], {
        value: `${res[0].name} (${res[0].code})`,
        isNearby: false
      });
    else return { code: "", country: "", name: "", value: "", isNearby: false };
  });

export const getMatchedAirports = async (dep, des) =>
  await Promise.all([getMatchedAirport(dep), getMatchedAirport(des)]).then(
    res => res
  );

export const getFlightsSearchUrl = searchbox => {
  const {
    departure,
    destination,
    isOneway,
    departureDate,
    returnDate,
    travellers,
    cabinClass,
    competitors
  } = searchbox;
  const url = [];
  url.push(departure.code);
  url.push(departure.isNearby ? 1 : 0);
  url.push(destination.code);
  url.push(destination.isNearby ? 1 : 0);
  url.push(departureDate.replace(/\//g, "-"));
  url.push(isOneway ? "-" : returnDate.replace(/\//g, "-"));
  url.push(travellers.adt + "-" + travellers.ch + "-" + travellers.inf);
  url.push(cabinClass.code);
  url.push(competitors.length ? competitors.join("_") : "-");
  return url.join("/");
};

export const formatPrice = x => Math.round(x);

export const displayPrice = (y, fixed = 0) => {
  const x = y.toFixed(fixed);
  const { symbol } = currencies[currency.toUpperCase()];
  if (["eur", "pln"].includes(currency)) return `${x} ${symbol}`;
  if (currency === "usd") return `${symbol}${x}`;
  return `${symbol} ${x}`;
};

export const resetFlightsFilter = (key, updateFilterValue, edges, isOneway) => {
  updateFilterValue(key, "price", edges.price.max);
  updateFilterValue(key, "outboundDuration", edges.outbound.duration.max);
  updateFilterValue(key, "inboundDuration", edges.inbound.duration.max);
  updateFilterValue(key, "departureOut", edges.outbound.departure);
  updateFilterValue(key, "arrivalOut", edges.outbound.arrival);
  if (!isOneway) {
    updateFilterValue(key, "departureIn", edges.inbound.departure);
    updateFilterValue(key, "arrivalIn", edges.inbound.arrival);
  }
  const airlines = {};
  Object.keys(edges.airlines).forEach(code => (airlines[code] = true));
  updateFilterValue(key, "airlines", airlines);
  const shops = {};
  Object.keys(edges.shops).forEach(code => (shops[code] = true));
  updateFilterValue(key, "shops", shops);
  const stops = Object.keys(edges.stops).filter(
    key => edges.stops[key] < 999999
  );
  updateFilterValue(key, "stops", stops[stops.length - 1]);
};

const metaReplacements = (key, values) =>
  getLabel(key, {
    ...values,
    serviceName: getLabel("service.name")
  });

const meta = [
  { name: "description", content: metaReplacements("meta.description") }
];

export const renderMeta = (title, values) => (
  <Helmet title={metaReplacements(`meta.title.${title}`, values)} meta={meta} />
);

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const getStopsLabel = stops =>
  stops === 0
    ? getLabel("direct")
    : stops + " " + getLabel(stops === 1 ? "stop" : "stop.s");

export const getArrivalNextDay = bounds => {
  if (!bounds) return false;
  const departure = bounds[0];
  const destination = bounds[bounds.length - 1];
  return (
    getTime(departure.dep.time, "D") !== getTime(destination.arr.time, "D")
  );
};

export const getFlightsCriteriaKey = ({
  departure,
  destination,
  departureDate,
  isOneway,
  returnDate,
  travellers: { adt, ch, inf },
  cabinClass
}) =>
  [
    departure.code,
    destination.code,
    departureDate,
    isOneway ? "oneway" : returnDate,
    adt,
    ch,
    inf,
    cabinClass.code
  ].join("-");

export const getTravellersText = ({ adt, ch, inf }) => {
  const travellers = adt + ch + inf;
  return (
    travellers + " " + getLabel(travellers === 1 ? "traveller" : "travellers")
  );
};
