import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import {
  setFlightsActiveSearchKey,
  removeFlightsSearchKey
} from "../../../../redux/actions";
import {
  getLabel,
  getTime,
  getTravellersText
} from "../../../../utils/functions";
require("./style.scss");

const ResultsTabs = ({
  tabs,
  activeSearchKey,
  setFlightsActiveSearchKey,
  removeFlightsSearchKey
}) => (
  <div className="resultsTabs">
    <div className="tabs">
      {tabs.map(
        ({
          key,
          departure,
          destination,
          departureDate,
          isOneway,
          returnDate,
          travellers,
          cabinClass
        }) => (
          <div
            key={key}
            className={cx("tab", { active: key === activeSearchKey })}
            onClick={
              key === activeSearchKey
                ? undefined
                : setFlightsActiveSearchKey(key)
            }
          >
            <div>
              {departure.code} - {destination.code}:{" "}
              {getTime(
                departureDate
                  .split("/")
                  .reverse()
                  .join("-"),
                "ddd, D/M"
              )}
              {!isOneway && (
                <>
                  {" "}
                  -{" "}
                  {getTime(
                    returnDate
                      .split("/")
                      .reverse()
                      .join("-"),
                    "ddd, D/M"
                  )}
                </>
              )}
              <div className="mini">
                {getTravellersText(travellers)},{" "}
                {getLabel(`class.${cabinClass.code}`)}
              </div>
            </div>
            {tabs.length > 1 && (
              <FontAwesomeIcon
                className="close"
                icon={faTimes}
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  if (key === activeSearchKey) {
                    setFlightsActiveSearchKey(
                      tabs.find(({ key }) => key !== activeSearchKey).key
                    )();
                  }
                  removeFlightsSearchKey(key);
                }}
              />
            )}
          </div>
        )
      )}
    </div>
    <label className="select">
      <select
        onChange={({ target: { value } }) => {
          if (value !== activeSearchKey) {
            setFlightsActiveSearchKey(value)();
          }
        }}
        value={activeSearchKey}
      >
        {tabs.map(
          ({
            key,
            departure,
            destination,
            departureDate,
            isOneway,
            returnDate
          }) => (
            <option key={key} value={key}>
              {departure.code} - {destination.code}:{" "}
              {getTime(
                departureDate
                  .split("/")
                  .reverse()
                  .join("-"),
                "ddd, D/M"
              )}
              {!isOneway &&
                ` - ${getTime(
                  returnDate
                    .split("/")
                    .reverse()
                    .join("-"),
                  "ddd, D/M"
                )}`}
            </option>
          )
        )}
      </select>
      <FontAwesomeIcon className="iconDown" icon={faChevronDown} />
    </label>
  </div>
);

export default connect(
  state => ({
    tabs: Object.keys(state.flights.searches).map(key => ({
      key,
      ...state.flights.searches[key].criteria
    })),
    activeSearchKey: state.flights.activeSearchKey
  }),
  dispatch => ({
    setFlightsActiveSearchKey: key => () =>
      dispatch(setFlightsActiveSearchKey(key)),
    removeFlightsSearchKey: key => dispatch(removeFlightsSearchKey(key))
  })
)(ResultsTabs);
