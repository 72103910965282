import React from "react";
import cx from "classnames";
require("./style.scss");

class Balloon extends React.Component {
  constructor(props) {
    super(props);
    this.ref = null;
  }

  scrollTop = () => {
    this.ref.scrollTop = 0;
    this.ref.scrollLeft = 0;
  };

  render() {
    const { className } = this.props;
    return (
      <div className={cx("balloon", className)}>
        <div
          ref={r => {
            this.ref = r;
            if (this.ref) {
              this.ref.addEventListener(
                "touchstart",
                event => {
                  event.stopPropagation();
                },
                { passive: true }
              );
            }
          }}
          className="in"
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Balloon;
