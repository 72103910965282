import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import ResultsPanel from "../_commons/ResultsPanel/index.jsx";
import {
  updateTravellers,
  updateCabinClass,
  updateSearchboxOneway,
  autocompleteAirportsNearby,
  pushFlightsResults,
  resetFlightsResults,
  increaseFlightsResponseCounter,
  resetFlightsResponseCounter,
  updateFlightsFilterEdges,
  updateFlightsFilter,
  setFlightsCriteria,
  datepickerDateSelected,
  autocompleteAirportSelected
} from "../../../redux/actions";
import init from "../../../redux/store/flights/init";
import { refillSearchbox, makeSearch } from "./functions.js";
require("./style.scss");

class ResultsPage extends React.Component {
  componentDidMount() {
    if (this.props.searchbox.isFilled) {
      makeSearch(this.props);
      return;
    }
    refillSearchbox(this.props).then(res => {
      if (!res) {
        return;
      }
      makeSearch(Object.assign({}, this.props, { searchbox: res }));
    });
  }

  shouldComponentUpdate({ searchbox }) {
    return searchbox.isFilled;
  }

  componentDidUpdate() {
    makeSearch(this.props);
  }

  render() {
    return <ResultsPanel />;
  }
}

export default connect(
  state => ({
    searchbox: state.flights.searchbox,
    edges: { ...cloneDeep(init.edges) }
  }),
  dispatch => ({
    updateTravellers: (traveller, value) =>
      dispatch(updateTravellers(traveller, value)),
    updateCabinClass: cabinClass => dispatch(updateCabinClass(cabinClass)),
    updateIsOneway: isOneway => dispatch(updateSearchboxOneway(isOneway)),
    updateAirportsNearby: (direction, isNearby) =>
      dispatch(autocompleteAirportsNearby(direction, isNearby)),
    pushResults: (results, criteria) =>
      dispatch(pushFlightsResults(results, criteria)),
    resetFlightsResults: () => dispatch(resetFlightsResults()),
    resetFlightsResponseCounter: () => dispatch(resetFlightsResponseCounter()),
    increaseResponseCounter: criteria =>
      dispatch(increaseFlightsResponseCounter(criteria)),
    updateFilterEdges: (edges, key) =>
      dispatch(updateFlightsFilterEdges(edges, key)),
    updateFilterValue: (key, field, value) =>
      dispatch(updateFlightsFilter(key, field, value)),
    setFlightsCriteria: criteria => dispatch(setFlightsCriteria(criteria)),
    updateDate: (field, date) => dispatch(datepickerDateSelected(field, date)),
    updateAirport: (direction, value, airport) =>
      dispatch(autocompleteAirportSelected(direction, value, airport))
  })
)(ResultsPage);
