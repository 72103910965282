import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Autocomplete from "./Autocomplete/index.jsx";
import DatePicker from "../_commons/DatePicker/index.jsx";
import ComboboxTravellers from "./ComboboxTravellers/index.jsx";
import Loader from "../../_commons/Loader";
import {
  validateFlightSearchbox,
  setFlightsSearchboxFilled,
  resetFlightsResults,
  resetFlightsResponseCounter
} from "../../../redux/actions";
import { redirect, getFlightsSearchUrl } from "../../../utils/functions";
import config from "../../../config";
import cx from "classnames";
require("./style.scss");

const shopsCount = config.shops.length;

const handleSubmit = (
  searchbox,
  onValidate,
  setIsFilled,
  resetResults,
  resetResponseCounter,
  event
) => {
  event.preventDefault();
  const validations = {};
  if (!searchbox.departure.value) validations.departure = "empty";
  else if (!searchbox.departure.code) validations.departure = "wrong";
  if (!searchbox.destination.value) validations.destination = "empty";
  else if (!searchbox.destination.code) validations.destination = "wrong";
  if (
    searchbox.departure.code &&
    searchbox.departure.code === searchbox.destination.code
  )
    validations.destination = "same";
  if (!searchbox.departureDate) validations.dates = true;
  onValidate(validations);
  if (Object.keys(validations).length) return;
  setIsFilled(true);
  resetResults();
  resetResponseCounter();
  redirect("/flights/" + getFlightsSearchUrl(searchbox));
};

const Searchbox = ({
  narrow,
  searchbox,
  onValidate,
  setIsFilled,
  resetResults,
  resetResponseCounter,
  loading,
  className
}) => (
  <form
    className={cx("searchbox", className, { wide: !narrow, narrow })}
    onSubmit={handleSubmit.bind(
      this,
      searchbox,
      onValidate,
      setIsFilled,
      resetResults,
      resetResponseCounter
    )}
  >
    <Autocomplete direction="departure" />
    <Autocomplete direction="destination" />
    <DatePicker />
    <ComboboxTravellers />
    <button disabled={loading}>
      {loading ? <Loader small /> : <FontAwesomeIcon icon={faSearch} />}
    </button>
  </form>
);

export default connect(
  state => ({
    searchbox: state.flights.searchbox,
    loading:
      (
        state.flights.searches[state.flights.activeSearchKey] || {
          responsesCounter: shopsCount
        }
      ).responsesCounter < shopsCount
  }),
  dispatch => ({
    onValidate: validations => dispatch(validateFlightSearchbox(validations)),
    setIsFilled: isFilled => dispatch(setFlightsSearchboxFilled(isFilled)),
    resetResults: () => dispatch(resetFlightsResults()),
    resetResponseCounter: () => dispatch(resetFlightsResponseCounter())
  })
)(Searchbox);
