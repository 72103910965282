import { combineReducers } from "redux";
import searchbox from "./searchbox.js";
import srp from "./srp.js";

export default combineReducers({
  searchbox: combineReducers({
    isFilled: searchbox.isFilled,
    departure: searchbox.airport.departure,
    destination: searchbox.airport.destination,
    isOneway: searchbox.isOneway,
    departureDate: searchbox.date.departure,
    returnDate: searchbox.date.return,
    travellers: searchbox.travellers,
    cabinClass: searchbox.cabinClass,
    competitors: searchbox.competitors
  }),
  searchboxValidation: searchbox.validation,
  // results: srp.results,
  // criteria: srp.criteria,
  // responsesCounter: srp.responsesCounter,
  // sorting: srp.sorting,
  // paging: srp.paging,
  // filter: combineReducers({
  //   values: srp.filter,
  //   edges: srp.edges
  // }),
  filterVisible: srp.filterVisible,
  searches: srp.searches,
  activeSearchKey: srp.activeSearchKey
});
