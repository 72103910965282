import React from 'react'
require('./style.scss')

class PanelAside extends React.Component {

    render() {
        return (<div className="panelAside">{this.props.children}</div>)
    }

}

export default PanelAside
