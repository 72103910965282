import React from "react";
import Searchbox from "../Searchbox/Flights/index.jsx";
import { getLabel } from "../../utils/functions";
require("../HomePage/style.scss");

class ErrorPage404 extends React.Component {
  render() {
    return (
      <div className="page pageCentered homepage">
        <div className="box">
          <div className="error404">
            <big>{getLabel("error.404")}</big>
            {getLabel("error.404.message")}
          </div>
          <h1>{getLabel("find.the.cheapest.flight.tickets")}</h1>
          <Searchbox />
        </div>
      </div>
    );
  }
}

export default ErrorPage404;
