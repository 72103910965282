import React from "react";
import { Link } from "react-router-dom";
import PageAsides from "../_commons/PageAsides/index.jsx";
import { getLabel, getLocalization } from "../../utils/functions";
import config from "../../config";
import cx from "classnames";
require("./style.scss");

const { routes } = config;
// const lang = getLocalization().language;

const label = label => {
  if (label === "faq") return "frequently.asked.questions";
  return label;
};

class ContentPage extends React.Component {
  getAside() {
    return (
      <div className="menu">
        {routes
          .filter(item => item.inPages)
          .map((item, index) => (
            <Link
              to={item.labels[getLocalization().language]}
              key={index}
              className={cx("menuItem", {
                active:
                  item.labels[getLocalization().language] === this.props.page
              })}
            >
              {getLabel(label(item.labels.EN.replace(/-/g, ".")))}
            </Link>
          ))}
      </div>
    );
  }

  render() {
    return (
      <PageAsides
        kind="contentPage"
        aside={this.getAside()}
        main={this.props.children}
      />
    );
  }
}

export default ContentPage;
