import React, { Component } from "react";
// import FlightOverview from "../../../ResultsPage/Flights/FlightOverview/index.jsx";
import Loader from "../../../_commons/Loader";
import { getLabel } from "../../../../utils/functions";
require("./style.scss");

export default class RedirectDetails extends Component {
  render() {
    const {
      shop
      // flightKey
    } = this.props;
    // let fare = null;
    // if (typeof Storage !== "undefined") {
    //   fare = sessionStorage[`fare-${flightKey}`];
    //   try {
    //     fare = JSON.parse(fare);
    //   } catch (e) {}
    // }
    return (
      <div className="redirectDetailsWrap">
        <div className="message">
          <span>{getLabel("you.are.being.redirected.to")}</span>
          <img
            src={require(`../../../../static/images/icons/${shop}.png`)}
            alt={shop}
          />
        </div>
        <div className="loading">
          <span>{getLabel("please.wait")}...</span>
          <Loader className="loader" />
          {/*<img
            src={require("../../../../static/images/icons/loader.gif")}
            alt="Loader"
          />*/}
        </div>
        {/*fare !== null && (
          <div>
            <div className="text">{getLabel("you.selected.this.flight")}</div>
            <div className="fareOverview">
              <FlightOverview {...fare} />
            </div>
          </div>
        )*/}
      </div>
    );
  }
}
