import cloneDeep from "lodash/cloneDeep";
import init from "./init.js";
import { getFlightsCriteriaKey } from "../../../utils/functions";

// const results = (state = init.results, action) => {
//   switch (action.type) {
//     case "PUSH_FLIGHTS_RESULTS":
//       const newState = [...state];
//       const map = {};
//       newState.map(fare => (map[fare.key] = fare));
//       action.results.forEach(fare => {
//         const foundFare = map[fare.key];
//         if (foundFare) {
//           foundFare.shops.push(fare.shops[0]);
//           foundFare.shops = foundFare.shops.sort((a, b) =>
//             a.price > b.price ? 1 : -1
//           );
//           foundFare.price = Math.min(foundFare.price, fare.price);
//         } else {
//           newState.push(fare);
//         }
//       });
//       return newState;
//     case "RESET_FLIGHTS_RESULTS":
//       return [];
//     default:
//       return state;
//   }
// };

// const responsesCounter = (state = 0, action) => {
//   switch (action.type) {
//     case "INCREASE_FLIGHTS_RESPONSE_COUNTER":
//       return state + 1;
//     case "RESET_FLIGHTS_RESPONSE_COUNTER":
//       return 0;
//     default:
//       return state;
//   }
// };

// const paging = (state = init.paging, action) => {
//   switch (action.type) {
//     case "CHANGE_FLIGHTS_PAGE":
//       return Object.assign({}, state, { page: action.page });
//     case "CREASE_FLIGHTS_PAGE":
//       return Object.assign({}, state, { page: state.page + action.step });
//     case "UPDATE_FLIGHTS_FILTER":
//       return { ...state, page: 0 };
//     default:
//       return state;
//   }
// };

// const sorting = (state = init.sorting, action) => {
//   switch (action.type) {
//     case "SET_FLIGHTS_SORTING":
//       return action.sorting;
//     case "RESET_FLIGHTS_RESULTS":
//       return cloneDeep(init.sorting);
//     default:
//       return state;
//   }
// };

// const filter = (state = init.filter, action) => {
//   switch (action.type) {
//     case "UPDATE_FLIGHTS_FILTER":
//       const newState = Object.assign({}, state);
//       if (action.param !== null) {
//         if (action.operation === "ONLY") {
//           Object.keys(newState[action.field]).forEach(key => {
//             newState[action.field][key] = false;
//           });
//           newState[action.field][action.param] = true;
//         } else if (action.param === "ALL") {
//           Object.keys(newState[action.field]).forEach(key => {
//             newState[action.field][key] = action.value;
//           });
//         } else {
//           newState[action.field][action.param] = action.value;
//         }
//       } else {
//         newState[action.field] = action.value;
//       }
//       return newState;
//     case "RESET_FLIGHTS_RESULTS":
//       return cloneDeep(init.filter);
//     default:
//       return state;
//   }
// };

// const edges = (state = cloneDeep(init.edges), action) => {
//   switch (action.type) {
//     case "UPDATE_FLIGHTS_FILTER_EDGES":
//       return { ...cloneDeep(action.edges) };
//     case "RESET_FLIGHTS_RESULTS":
//       return { ...cloneDeep(init.edges) };
//     default:
//       return state;
//   }
// };

// const criteria = (state = {}, action) => {
//   switch (action.type) {
//     case "SET_FLIGHTS_CRITERIA":
//       return action.criteria;
//     case "RESET_FLIGHTS_RESULTS":
//       return {};
//     default:
//       return state;
//   }
// };

const filterVisible = (state = init.filterVisible, action) => {
  switch (action.type) {
    case "TOGGLE_FLIGHTS_FILTER_VISIBLE":
      return !state;
    case "RESET_FLIGHTS_RESULTS":
      return cloneDeep(init.filterVisible);
    default:
      return state;
  }
};

const searches = (state = {}, action) => {
  try {
    const key = action.criteria
      ? getFlightsCriteriaKey(action.criteria)
      : "none";
    switch (action.type) {
      case "REMOVE_FLIGHTS_SEARCH_KEY":
        return Object.keys(state).reduce(
          (aggr, key) => ({
            ...aggr,
            ...(key === action.key ? {} : { [key]: state[key] })
          }),
          {}
        );
      case "SET_FLIGHTS_CRITERIA":
        return {
          ...state,
          [key]: {
            ...state[key],
            criteria: action.criteria,
            results: [],
            responsesCounter: 0,
            sorting: init.sorting,
            paging: init.paging,
            filter: {
              values: init.filter,
              edges: init.edges
            }
          }
        };
      case "PUSH_FLIGHTS_RESULTS":
        const results = [...state[key].results];
        const map = {};
        results.map(fare => (map[fare.key] = fare));
        action.results.forEach(fare => {
          const foundFare = map[fare.key];
          if (foundFare) {
            foundFare.shops.push(fare.shops[0]);
            foundFare.shops = foundFare.shops.sort((a, b) =>
              a.price > b.price ? 1 : -1
            );
            foundFare.price = Math.min(foundFare.price, fare.price);
          } else {
            results.push(fare);
          }
        });
        return {
          ...state,
          [key]: {
            ...state[key],
            results
          }
        };
      case "INCREASE_FLIGHTS_RESPONSE_COUNTER":
        return {
          ...state,
          [key]: {
            ...state[key],
            responsesCounter: state[key].responsesCounter + 1
          }
        };
      case "SET_FLIGHTS_SORTING":
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            sorting: action.sorting
          }
        };
      case "CHANGE_FLIGHTS_PAGE":
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            paging: {
              ...state[action.key].paging,
              page: action.page
            }
          }
        };
      case "CREASE_FLIGHTS_PAGE":
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            paging: {
              ...state[action.key].paging,
              page: state[action.key].paging.page + action.step
            }
          }
        };
      case "UPDATE_FLIGHTS_FILTER":
        const newState = Object.assign({}, state[action.key].filter.values);
        if (action.param !== null) {
          if (action.operation === "ONLY") {
            Object.keys(newState[action.field]).forEach(key => {
              newState[action.field][key] = false;
            });
            newState[action.field][action.param] = true;
          } else if (action.param === "ALL") {
            Object.keys(newState[action.field]).forEach(key => {
              newState[action.field][key] = action.value;
            });
          } else {
            newState[action.field][action.param] = action.value;
          }
        } else {
          newState[action.field] = action.value;
        }
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            filter: {
              ...state[action.key].filter,
              values: newState
            }
          }
        };
      case "UPDATE_FLIGHTS_FILTER_EDGES":
        return {
          ...state,
          [action.key]: {
            ...state[action.key],
            filter: {
              ...state[action.key].filter,
              edges: { ...cloneDeep(action.edges) }
            }
          }
        };
      default:
        return state;
    }
  } catch (err) {
    return state;
  }
};

const activeSearchKey = (state = "none", action) => {
  const key = action.criteria ? getFlightsCriteriaKey(action.criteria) : "none";
  switch (action.type) {
    case "SET_FLIGHTS_CRITERIA":
      return key;
    case "SET_FLIGHTS_ACTIVE_SEARCH_KEY":
      return action.key;
    default:
      return state;
  }
};

export default {
  // results,
  // responsesCounter,
  // paging,
  // sorting,
  // filter,
  // edges,
  // criteria,
  filterVisible,
  searches,
  activeSearchKey
};
