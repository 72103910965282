import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import {
  changeFlightsPage,
  creaseFlightsPage
} from "../../../../redux/actions";
import { getFilteredResults } from "../../Flights/functions.js";
import { getLabel } from "../../../../utils/functions";
import cx from "classnames";
require("./style.scss");

const Spacing = () => <span>...</span>;

const renderButtons = (pagesAmount, page, changePage, activeSearchKey) => {
  const range = 1;
  const buttons = [];
  for (let i = 0; i < pagesAmount; i++) {
    buttons.push(
      <button
        key={Math.random()}
        onClick={page !== i ? changePage(i, activeSearchKey) : null}
        className={cx({ active: page === i })}
      >
        {i + 1}
      </button>
    );
  }
  let min = page - range;
  let max = page + range + 1;
  if (min < 0) {
    max -= min;
    min = 0;
  }
  if (max >= pagesAmount) {
    min -= max - pagesAmount;
    max = pagesAmount;
  }
  min = Math.max(0, min);
  const visibleButtons = [...buttons.slice(min, max)];
  const indexes = {};
  for (let i = min; i < max; i++) {
    indexes[i] = true;
  }
  if (page >= 2 * range) {
    if (pagesAmount > 2 * range + 2 && page >= 2 * range + 1)
      visibleButtons.unshift(<Spacing key="spacing-prev" />);
    if (!indexes[0]) visibleButtons.unshift(buttons[0]);
  }
  if (page <= pagesAmount - 2 * range) {
    if (pagesAmount > 2 * range + 2 && page <= pagesAmount - 2 * range - 2)
      visibleButtons.push(<Spacing key="spacing-next" />);
    if (!indexes[buttons.length - 1])
      visibleButtons.push(buttons[buttons.length - 1]);
  }
  return visibleButtons;
};

class PagingBar extends Component {
  render() {
    const {
      results,
      paging,
      filter,
      sorting,
      changePage,
      increasePage,
      decreasePage,
      activeSearchKey
    } = this.props;
    const { page, perPage } = paging;
    const filteredResults = getFilteredResults(results, filter, sorting);
    const resultsAmount = filteredResults.length;
    const pagesAmount = Math.ceil(resultsAmount / perPage);
    const pageMax = Math.min(page, pagesAmount - 1);
    const pageNr = pageMax + 1;
    const start = 1 + perPage * pageMax;
    const end = Math.min(perPage * pageNr, resultsAmount);
    if (pagesAmount && page !== pageMax)
      setTimeout(changePage(pageMax, activeSearchKey), 0);
    const noResults = resultsAmount === 0;
    return (
      <div className={cx("pagingBar", { noResults })}>
        {pagesAmount > 1 && (
          <span className="buttons">
            {pageMax > 0 && (
              <button
                onClick={decreasePage(activeSearchKey)}
                className={cx("next", "prev")}
              >
                Prev
              </button>
            )}
            {renderButtons(pagesAmount, pageMax, changePage, activeSearchKey)}
            {pageMax < pagesAmount - 1 && (
              <button
                onClick={
                  pageMax < pagesAmount - 1
                    ? increasePage(activeSearchKey)
                    : null
                }
                className={cx("next", {
                  disabled: pageMax === pagesAmount - 1
                })}
              >
                Next
              </button>
            )}
          </span>
        )}
        <span className="text">
          {noResults && (
            <span>
              <FontAwesomeIcon className="icon" icon={faExclamationTriangle} />
              {getLabel("no.filtered.results.found")}
            </span>
          )}
          {resultsAmount > 0 && (
            <span>
              <strong>{start}</strong> {getLabel("paging.to")}{" "}
              <strong>{end}</strong> {getLabel("paging.of")}{" "}
              <strong>{resultsAmount}</strong> {getLabel("paging.results")}
            </span>
          )}
        </span>
      </div>
    );
  }
}

const scrollTop = () => {
  if (window) {
    window.scrollTo(0, 0);
  }
};

export default connect(
  state => ({
    results: state.flights.searches[state.flights.activeSearchKey].results,
    paging: state.flights.searches[state.flights.activeSearchKey].paging,
    filter: state.flights.searches[state.flights.activeSearchKey].filter.values,
    sorting: state.flights.searches[state.flights.activeSearchKey].sorting,
    activeSearchKey: state.flights.activeSearchKey
  }),
  dispatch => ({
    changePage: (page, key) => () => {
      scrollTop();
      dispatch(changeFlightsPage(page, key));
    },
    increasePage: key => () => {
      scrollTop();
      dispatch(creaseFlightsPage(1, key));
    },
    decreasePage: key => () => {
      scrollTop();
      dispatch(creaseFlightsPage(-1, key));
    }
  })
)(PagingBar);
