import { getTime, formatDate, getLabel } from "../../../utils/functions";

const timeMin = +getTime("2099-12-31 12:00:00", "X");
const timeMax = +getTime("2009-12-31 12:00:00", "X");
const DAY = 24 * 60 * 60000;

const predefined = {
  searchbox: {
    departure: { code: "", name: "", country: "", value: "", isNearby: false },
    destination: {
      code: "",
      name: "",
      country: "",
      value: "",
      isNearby: false
    },
    departureDate: formatDate(new Date(Date.now() + 2 * DAY), "DD/MM/YYYY"),
    returnDate: formatDate(new Date(Date.now() + 5 * DAY), "DD/MM/YYYY")
  }
};

const defaultCabinClassCode = "ECONOMY";

export default {
  searchbox: {
    isOneway: false,
    departure: predefined.searchbox.departure,
    destination: predefined.searchbox.destination,
    departureDate: predefined.searchbox.departureDate,
    returnDate: predefined.searchbox.returnDate,
    travellers: {
      adt: 1,
      ch: 0,
      inf: 0
    },
    cabinClass: {
      code: defaultCabinClassCode,
      name: getLabel(`class.${defaultCabinClassCode}`)
    },
    competitors: []
  },
  results: [],
  paging: {
    page: 0,
    perPage: 20
  },
  sorting: {
    key: "price",
    isAscending: true
  },
  filter: {
    price: 0,
    outboundDuration: 0,
    inboundDuration: 0,
    departureOut: {
      min: null,
      max: null
    },
    departureIn: {
      min: null,
      max: null
    },
    arrivalOut: {
      min: null,
      max: null
    },
    arrivalIn: {
      min: null,
      max: null
    },
    stops: "zero",
    airlines: {},
    shops: {},
    combinedAirlines: true
  },
  filterVisible: false,
  edges: {
    price: {
      min: 999999,
      max: 0
    },
    outbound: {
      departure: {
        min: timeMin,
        max: timeMax
      },
      arrival: {
        min: timeMin,
        max: timeMax
      },
      duration: {
        min: 999999,
        max: 0
      }
    },
    inbound: {
      departure: {
        min: timeMin,
        max: timeMax
      },
      arrival: {
        min: timeMin,
        max: timeMax
      },
      duration: {
        min: 999999,
        max: 0
      }
    },
    stops: {
      zero: 999999,
      one: 999999,
      two: 999999,
      more: 999999
    },
    airlines: {},
    shops: {}
  }
};
