import React from 'react'
require("./style.scss");

class Banner extends React.Component {
    render() {
        return (
            <div className="Banner">
                <div className="in">
                    
                </div>
            </div>
        )
    }
}

export default Banner;