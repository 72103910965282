import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faBed, faCar } from "@fortawesome/free-solid-svg-icons";
import { getLabel } from "../../../../../utils/functions";

require("./style.scss");
class MenuMain extends React.Component {
  render() {
    return (
      <div className="menuMain">
        <Link to={"/"} className="item active">
          <FontAwesomeIcon icon={faPlane} className="icon" />
          {getLabel("flights")}
        </Link>
        <a href={getLabel('/hotels')} className="item">
          <FontAwesomeIcon icon={faBed} className="icon" />
          {getLabel("hotels")}
        </a>
        <a href={getLabel('/cars')} className="item">
          <FontAwesomeIcon icon={faCar} className="icon" />
          {getLabel("cars")}
        </a>
      </div>
    );
  }
}

export default MenuMain;
