import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../../_commons/Loader";
import Modal from "../../../_commons/Modal";
import BoundDetails from "../BoundDetails/index.jsx";
import FlightOverview from "../FlightOverview/index.jsx";
import { getLabel, displayPrice } from "../../../../utils/functions";
import { toggle } from "../../../../redux/actions";
import config from "../../../../config";
require("./style.scss");

const storeFare = fare => () => {
  if (typeof Storage === "undefined") return;
  sessionStorage[`fare-${fare.key}`] = JSON.stringify(fare);
};

const Deeplink = ({ shop, fare, children }) => (
  <a
    href={`#/redirect/${shop.code}/${fare.key}?url=${window.encodeURIComponent(
      shop.url
    )}`}
    target="_blank"
    rel="noopener noreferrer"
    onClick={storeFare(fare)}
  >
    {children}
  </a>
);

class FlightResult extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: {
        details: false
      }
    };
  }

  toggleVisible = what => () => {
    const { state } = this;
    state.visible[what] = !state.visible[what];
    this.setState(state);
  };

  render() {
    const { fare, loading, filterShops } = this.props;
    const {
      outbounds,
      inbounds,
      durations,
      shops: fareShops,
      cabinClass
    } = fare;
    const departure = outbounds[0];
    const destination = outbounds[outbounds.length - 1];
    const shops = fareShops.filter(({ code }) => filterShops.includes(code));
    return (
      <>
        <div className="flightResult" onClick={this.toggleVisible("details")}>
          <FlightOverview {...fare} />
          <div className="bottomBar">
            <div className="bottomBarBtns">
              {loading && <Loader small className="fareLoader" />}
              <div>
                <div className="price">{displayPrice(shops[0].price, 2)}</div>
                <div className="shops">
                  {shops.length === 1
                    ? `${getLabel("with")} ${shops[0].name}`
                    : `${shops.length} ${getLabel("offers")}`}
                </div>
              </div>
              <button>{getLabel("book.now")}</button>
            </div>
            <div className="rightBarBtns">
              <div>
                <button>
                  {getLabel("book.from")}
                  <strong>{displayPrice(shops[0].price, 2)}</strong>
                </button>
                <div className="cheapestShop">
                  {getLabel("with")} {shops[0].name}
                </div>
                {loading && <Loader small />}
              </div>
              <div>
                {shops
                  .filter((_, idx) => idx > 0 && idx <= 2)
                  .map((shop, index) => (
                    <div key={index} className="shop">
                      <span className="name">{shop.name}</span>
                      <span className="price">
                        {displayPrice(shop.price, 2)}
                      </span>
                    </div>
                  ))}
                {shops.some((_, idx) => idx > 2) && (
                  <div className="shop more">
                    {getLabel("more.offers")} (
                    {shops.filter((_, idx) => idx > 2).length})
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={this.state.visible.details}
          onRequestClose={this.toggleVisible("details")}
          contentLabel="Fare details"
        >
          <div className="content">
            <div className="head">
              <FontAwesomeIcon
                icon={faTimes}
                className="close"
                onClick={this.toggleVisible("details")}
              />
              {departure.departureAirportCode} &gt;{" "}
              {destination.arrivalAirportCode}
            </div>
            <div className="shops">
              {shops.map((shop, index) => (
                <div key={index} className="shopBtn">
                  <div className="shop">
                    <span className="name">{shop.name}</span>
                    <div>
                      <span className="price">
                        {displayPrice(shop.price, 2)}
                      </span>
                      <Deeplink shop={shop} fare={fare}>
                        <button className="choose">
                          {getLabel("book.now")}
                        </button>
                      </Deeplink>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="bounds">
              <BoundDetails
                type="outbound"
                bounds={outbounds}
                duration={durations.outbound}
                cabinClass={cabinClass}
              />
              <BoundDetails
                type="inbound"
                bounds={inbounds}
                duration={durations.inbound}
                cabinClass={cabinClass}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default connect(
  state => ({
    isVisible: state.toggles.flightsResultsPageShopList,
    loading:
      state.flights.searches[state.flights.activeSearchKey].responsesCounter <
      config.shops.length,
    filterShops: Object.keys(
      state.flights.searches[state.flights.activeSearchKey].filter.values.shops
    ).filter(
      key =>
        state.flights.searches[state.flights.activeSearchKey].filter.values
          .shops[key]
    )
  }),
  dispatch => ({
    onToggle: (item, event) => {
      event.stopPropagation();
      dispatch(toggle("flightsResultsPageShopList", item));
    }
  })
)(FlightResult);
