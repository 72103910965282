// import Page from "./components/_commons/Page/index.jsx";
import HomePage from "./components/HomePage/index.jsx";
import ResultsPage from "./components/ResultsPage/Flights/index.jsx";
import ContentPageGeneral from "./components/ContentPageGeneral/index.jsx";
import ContentPageContact from "./components/ContentPageContact/index.jsx";
import ContentPageFaq from "./components/ContentPageFaq/index.jsx";
import ErrorPage404 from "./components/ErrorPage404/index.jsx";
import RedirectPage from "./components/RedirectPage/Flights/index.jsx";
import { getLocalization } from "./utils/functions";
import config from "./config";

const { routes } = config;
const lang = getLocalization().language;

const getComponentType = type => {
  switch (type) {
    case "contact":
      return ContentPageContact;
    case "faq":
      return ContentPageFaq;
    default:
      return ContentPageGeneral;
  }
};

const getDynamicRoutes = () => {
  const arr = [];
  routes.forEach(item => {
    arr.push({
      path: item.labels[lang],
      component: getComponentType(item.type)
    });
  });
  return arr;
};

export default [
  ...getDynamicRoutes(),
  {
    path:
      "/flights/:departureCode/:departureIsNearby/:destinationCode/:destinationIsNearby/:departureDate/:returnDate/:travellers/:cabinClass/:competitors",
    component: ResultsPage
  },
  {
    path: "/redirect/:shop/:flightKey",
    component: RedirectPage,
    skipPageWrapper: true
  },
  {
    path: "/",
    exact: true,
    component: HomePage
  },
  {
    path: "*",
    component: ErrorPage404
  }
];
