import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
require("./style.scss");

export default ({ type, name, value, age, onUpdate, disabled }) => (
  <div className="travellersOption" onClick={event => event.stopPropagation()}>
    <div>
      <div className="name">{name}</div>
      <div className="age">{age}</div>
    </div>
    <div className="btns">
      <div
        className="btn minus"
        onClick={onUpdate.bind(this, type, -1)}
        disabled={value === (type === "adt" ? 1 : 0)}
      >
        <FontAwesomeIcon icon={faMinus} />
      </div>
      <div className="value">{value}</div>
      <div
        className="btn plus"
        onClick={onUpdate.bind(this, type, 1)}
        disabled={disabled}
      >
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  </div>
);
