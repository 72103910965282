import React, { Component } from "react";
import { getLabel } from "../../../../utils/functions";
require("./style.scss");

class CookiesInfo extends Component {
  constructor(props) {
    super(props);
    let display = true;
    if (typeof Storage !== "undefined") {
      if (localStorage.hideCookieMsg) {
        display = false;
      }
    } else {
      // FIXME
    }
    this.state = {
      display
    };
  }

  handleClose = () => {
    this.setState({ display: false });
    if (typeof Storage !== "undefined") {
      localStorage.hideCookieMsg = true;
    } else {
      // FIXME
    }
  };

  render() {
    const { display } = this.state;
    if (!display) return null;
    return (
      <div className="cookies">
        <div className="text">
          {getLabel("cookies.message")}
          {' '}
          <a href={getLabel('/cookie.preferences')} className="link">
            {getLabel('learn.more')}
          </a>
        </div>
        <button className="close" onClick={this.handleClose}>
          {getLabel('accept.and.proceed')}
        </button>
      </div>
    );
  }
}

export default CookiesInfo;
