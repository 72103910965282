import React from "react";
import ContentPage from "../ContentPage/index.jsx";
import { getLabel, getLocalization } from "../../utils/functions";
import config from "../../config";
require("./style.scss");

const { routes } = config;
const lang = getLocalization().language;

class ContentPageGeneral extends React.Component {
  render() {
    const title = getLabel(
      routes
        .filter(item => item.labels[lang] === this.props.match.path)[0]
        .labels.EN.replace(/-/g, ".")
    );
    return (
      <ContentPage page={this.props.match.path}>
        <div className="contentPageGeneral">
          <h1>{title}</h1>
          <img
            className="mainPhoto"
            src={require("../../static/images/jpg/about-us.jpg")}
            alt="about us"
          />
          <div className="content">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin sed
              condimentum dolor. Praesent consequat sem arcu, at porttitor nisi
              efficitur et. Donec sed pellentesque libero. Vivamus vitae nulla
              dapibus, pulvinar orci nec, ornare tortor. Sed velit enim,
              sagittis sed nisi nec, laoreet vulputate eros. Aenean urna sem,
              fringilla a ipsum quis, fermentum accumsan purus. Ut nulla odio,
              consectetur eu dui at, bibendum mattis ipsum. Cras eget mauris sit
              amet orci iaculis pulvinar. Aliquam erat volutpat.
            </p>
            <p>
              Nam vestibulum justo et tempus bibendum. Aliquam commodo justo nec
              commodo gravida. Phasellus velit nibh, laoreet non suscipit ut,
              convallis sed ante. Suspendisse in aliquet dolor. Aliquam nisl
              orci, semper sed orci nec, luctus sagittis ligula. Curabitur a
              ipsum vel lectus tempor gravida. Vestibulum arcu metus, mollis
              vitae elit sit amet, suscipit pretium augue. Maecenas lobortis
              magna sed quam imperdiet, et volutpat libero congue. Praesent sit
              amet augue libero.
            </p>
            <p>
              Nam viverra metus et tellus sodales, eu ultricies sem venenatis.
              Cras sit amet dignissim justo. Aliquam sollicitudin finibus dolor.
              Etiam finibus elit at leo dictum, vitae pellentesque lorem
              hendrerit. Suspendisse tincidunt sit amet orci vel venenatis. Nunc
              vestibulum, dolor bibendum sollicitudin varius, lacus eros euismod
              libero, non pretium nibh justo ut orci. Vestibulum sodales
              venenatis feugiat. Fusce feugiat a lectus non imperdiet. Donec
              imperdiet euismod augue, et placerat ipsum semper ut. In
              elementum, odio sit amet sodales malesuada, risus ex posuere
              felis, non interdum diam ex id dui. Praesent feugiat libero et
              tristique viverra. Nam sagittis facilisis tortor, id placerat
              lorem cursus sed. Aenean augue libero, maximus vitae dui id,
              bibendum tincidunt turpis. Cras aliquam pellentesque tellus id
              ornare. Ut vitae ante dolor.
            </p>
          </div>
        </div>
      </ContentPage>
    );
  }
}

export default ContentPageGeneral;
