import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import config from "../../../../config";
import {
  getTime,
  formatDuration,
  formatPrice,
  displayPrice,
  getLabel
} from "../../../../utils/functions";
import { updateFlightsFilter } from "../../../../redux/actions";
import Checkbox from "../../../Searchbox/_commons/Checkbox/index.jsx";
import Slider from "../../../Searchbox/_commons/Slider/index.jsx";
import cx from "classnames";
require("./style.scss");

const airlinesLimit = Number.POSITIVE_INFINITY; //10;

const titles = {
  stops: getLabel("stops"),
  duration: getLabel("travel.duration"),
  airlines: getLabel("airlines"),
  shops: getLabel("shops"),
  price: getLabel("price"),
  outbound: getLabel("outbound.times"),
  inbound: getLabel("inbound.times")
};

const options = {
  stops: {
    zero: getLabel("direct"),
    one: getLabel("one.stop"),
    two: getLabel("two.stops"),
    more: getLabel("more.stops")
  }
};

const onSliderChange = (
  updateFilterValue,
  key,
  field,
  edge,
  value,
  param,
  operation
) => {
  if (field === "outbound" || field === "inbound") {
    if (value === "Duration") {
      updateFilterValue(key, field + value, param);
    } else {
      updateFilterValue(key, value, { min: param[0], max: param[1] });
    }
  } else if (field === "airlines") {
    if (value === "ALL") {
      updateFilterValue(key, field, param, value, "ALL");
    } else if (operation === "ONLY") {
      updateFilterValue(key, field, param, value, operation);
    } else {
      updateFilterValue(key, field, param, value);
    }
  } else if (field === "shops") {
    if (value === "ALL") {
      updateFilterValue(key, field, param, value, "ALL");
    } else if (operation === "ONLY") {
      updateFilterValue(key, field, param, value, operation);
    } else {
      updateFilterValue(key, field, param, value);
    }
  } else {
    updateFilterValue(key, field, value);
  }
};

const getContent = (
  activeSearchKey,
  type,
  edge,
  value,
  updateFilterValue,
  state,
  showAllAirlines,
  values
) => {
  if (!edge || !value) return null;
  if ((type === "outbound" || type === "inbound") && edge.duration.max === 0)
    return null;
  const content = [];
  switch (type) {
    case "stops":
      for (let item in options[type]) {
        if (edge[item] !== 999999) {
          content.push(
            <Checkbox
              key={item}
              checked={item === value}
              onChange={onSliderChange.bind(
                this,
                updateFilterValue,
                activeSearchKey,
                type,
                edge,
                item
              )}
              type="radio"
            >
              {options[type][item]}
              <span className="price">
                {getLabel("price.from")} {displayPrice(formatPrice(edge[item]))}
              </span>
            </Checkbox>
          );
        }
      }
      break;
    case "airlines":
      content.push(
        <Checkbox
          key="ALL"
          checked={Object.values(value).filter(val => !val).length === 0}
          onChange={onSliderChange.bind(
            this,
            updateFilterValue,
            activeSearchKey,
            type,
            edge,
            "ALL"
          )}
          onHoverLabel={getLabel("only")}
        >
          {getLabel("all")}
        </Checkbox>
      );
      Object.keys(edge)
        .sort((a, b) => {
          const A = edge[a].name.toLowerCase();
          const B = edge[b].name.toLowerCase();
          if (A > B) return 1;
          if (A < B) return -1;
          return 0;
        })
        .forEach(key => {
          if (typeof value[key] === "boolean") {
            if (
              state.allAirlines ||
              (!state.allAirlines && content.length < airlinesLimit)
            ) {
              content.push(
                <Checkbox
                  key={key}
                  checked={value[key]}
                  onChange={onSliderChange.bind(
                    this,
                    updateFilterValue,
                    activeSearchKey,
                    type,
                    edge,
                    key
                  )}
                  onHoverLabel={getLabel("only")}
                >
                  {edge[key].name}
                  <span className="price">
                    <span
                      className="hoverable"
                      onClick={event => {
                        onSliderChange(
                          updateFilterValue,
                          activeSearchKey,
                          type,
                          edge,
                          key,
                          !value[key],
                          "ONLY"
                        );
                        if (value[key]) {
                          onSliderChange(
                            updateFilterValue,
                            activeSearchKey,
                            "combinedAirlines",
                            edge,
                            false
                          );
                        }
                        event.preventDefault();
                      }}
                    >
                      {getLabel("only")}
                    </span>
                    {getLabel("price.from")}{" "}
                    {displayPrice(formatPrice(edge[key].price))}
                  </span>
                </Checkbox>
              );
            }
          }
        });
      if (Object.keys(edge).length > airlinesLimit) {
        content.push(
          <div key="showAll" className="showAll">
            <span onClick={showAllAirlines}>
              {getLabel(
                state.allAirlines ? "show.less.airlines" : "show.all.airlines"
              )}
            </span>
          </div>
        );
      }
      if (config.showIntermediateAirports) {
        content.push(
          <Checkbox
            key="airlinesCombination"
            checked={values.combinedAirlines}
            onChange={onSliderChange.bind(
              this,
              updateFilterValue,
              activeSearchKey,
              "combinedAirlines",
              edge,
              !values.combinedAirlines
            )}
          >
            {getLabel("airlines.combination")}
          </Checkbox>
        );
      }
      break;
    case "shops":
      content.push(
        <Checkbox
          key="ALL"
          checked={Object.values(value).filter(val => !val).length === 0}
          onChange={onSliderChange.bind(
            this,
            updateFilterValue,
            activeSearchKey,
            type,
            edge,
            "ALL"
          )}
          onHoverLabel={getLabel("only")}
        >
          {getLabel("all")}
        </Checkbox>
      );
      Object.keys(edge)
        .sort((a, b) => {
          const A = edge[a].name.toLowerCase();
          const B = edge[b].name.toLowerCase();
          if (A > B) return 1;
          if (A < B) return -1;
          return 0;
        })
        .forEach(key => {
          if (typeof value[key] === "boolean") {
            content.push(
              <Checkbox
                key={key}
                checked={value[key]}
                onChange={onSliderChange.bind(
                  this,
                  updateFilterValue,
                  activeSearchKey,
                  type,
                  edge,
                  key
                )}
                onHoverLabel={getLabel("only")}
              >
                {edge[key].name}
                <span className="price">
                  <span
                    className="hoverable"
                    onClick={event => {
                      onSliderChange(
                        updateFilterValue,
                        activeSearchKey,
                        type,
                        edge,
                        key,
                        !value[key],
                        "ONLY"
                      );
                      event.preventDefault();
                    }}
                  >
                    {getLabel("only")}
                  </span>
                  {getLabel("price.from")}{" "}
                  {displayPrice(formatPrice(edge[key].price))}
                </span>
              </Checkbox>
            );
          }
        });
      break;
    case "price":
      content.push(
        <div key="durationLabel" className="sliderLabel">
          <span className="min">{displayPrice(edge.min)}</span>
          <span>
            {value !== edge.max && (
              <span
                className="reset"
                onClick={onSliderChange.bind(
                  this,
                  updateFilterValue,
                  activeSearchKey,
                  type,
                  edge,
                  edge.max
                )}
              >
                {getLabel("reset")}
              </span>
            )}
            {displayPrice(value)}
          </span>
        </div>
      );
      content.push(
        <Slider
          key="slider"
          min={edge.min}
          max={edge.max}
          value={value}
          step={1}
          onChange={onSliderChange.bind(
            this,
            updateFilterValue,
            activeSearchKey,
            type,
            edge
          )}
        />
      );
      break;
    case "outbound":
    case "inbound":
      content.push(
        <div key="departureLabel" className="sliderLabel">
          <span className="min label">{getLabel("departure")}</span>
          <span>
            {(value.departure.min !== edge.departure.min ||
              value.departure.max !== edge.departure.max) && (
              <span
                className="reset"
                onClick={onSliderChange.bind(
                  this,
                  updateFilterValue,
                  activeSearchKey,
                  type,
                  edge,
                  "departure" + (type === "outbound" ? "Out" : "In"),
                  [edge.departure.min, edge.departure.max]
                )}
              >
                {getLabel("reset")}
              </span>
            )}
            {getTime(value.departure.min, "dd HH:mm", "X")}
            {" - "}
            {getTime(value.departure.max, "dd HH:mm", "X")}
          </span>
        </div>
      );
      content.push(
        <Slider
          key="departureSlider"
          range
          allowCross={false}
          min={edge.departure.min}
          max={edge.departure.max}
          value={[value.departure.min, value.departure.max]}
          step={300}
          onChange={onSliderChange.bind(
            this,
            updateFilterValue,
            activeSearchKey,
            type,
            edge,
            "departure" + (type === "outbound" ? "Out" : "In")
          )}
        />
      );
      content.push(
        <div key="arrivalLabel" className="sliderLabel">
          <span className="min label">{getLabel("arrival")}</span>
          <span>
            {(value.arrival.min !== edge.arrival.min ||
              value.arrival.max !== edge.arrival.max) && (
              <span
                className="reset"
                onClick={onSliderChange.bind(
                  this,
                  updateFilterValue,
                  activeSearchKey,
                  type,
                  edge,
                  "arrival" + (type === "outbound" ? "Out" : "In"),
                  [edge.arrival.min, edge.arrival.max]
                )}
              >
                {getLabel("reset")}
              </span>
            )}
            {getTime(value.arrival.min, "dd HH:mm", "X")}
            {" - "}
            {getTime(value.arrival.max, "dd HH:mm", "X")}
          </span>
        </div>
      );
      content.push(
        <Slider
          key="arrivalSlider"
          range
          allowCross={false}
          min={edge.arrival.min}
          max={edge.arrival.max}
          value={[value.arrival.min, value.arrival.max]}
          step={300}
          onChange={onSliderChange.bind(
            this,
            updateFilterValue,
            activeSearchKey,
            type,
            edge,
            "arrival" + (type === "outbound" ? "Out" : "In")
          )}
        />
      );
      content.push(
        <div key="durationLabel" className="sliderLabel">
          <span className="min label">{getLabel("duration")}</span>
          <span>
            {value.duration !== edge.duration.max && (
              <span
                className="reset"
                onClick={onSliderChange.bind(
                  this,
                  updateFilterValue,
                  activeSearchKey,
                  type,
                  edge,
                  "Duration",
                  edge.duration.max
                )}
              >
                {getLabel("reset")}
              </span>
            )}
            {formatDuration(value.duration)}
          </span>
        </div>
      );
      content.push(
        <Slider
          key={type + "duration"}
          min={edge.duration.min}
          max={edge.duration.max}
          value={value.duration}
          step={5}
          onChange={onSliderChange.bind(
            this,
            updateFilterValue,
            activeSearchKey,
            type,
            edge,
            "Duration"
          )}
        />
      );
      break;
    default:
  }
  return content;
};

class FilterBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: !props.closed,
      allAirlines: false
    };
  }

  toggleVisible = () => {
    this.setState({ visible: !this.state.visible });
  };

  toggleAllAirlines = () => {
    this.setState({ allAirlines: !this.state.allAirlines });
  };

  render() {
    const {
      type,
      edges,
      filter,
      updateFilterValue,
      activeSearchKey
    } = this.props;
    if (type === "inbound" && edges.inbound.duration.max === 0) return null;
    let edge = edges[type];
    let value = filter[type];
    if (type === "outbound" || type === "inbound") {
      value = {
        duration: filter[type + "Duration"],
        departure: filter["departure" + (type === "outbound" ? "Out" : "In")],
        arrival: filter["arrival" + (type === "outbound" ? "Out" : "In")]
      };
    }
    return (
      <div className={cx("filterBox", { visible: this.state.visible })}>
        <div className="title" onClick={this.toggleVisible}>
          {titles[type].charAt(0).toUpperCase() +
            titles[type].slice(1).toLowerCase()}
          <FontAwesomeIcon
            icon={this.state.visible ? faChevronDown : faChevronUp}
            className="icon"
          />
        </div>
        {this.state.visible &&
          getContent(
            activeSearchKey,
            type,
            edge,
            value,
            updateFilterValue,
            this.state,
            this.toggleAllAirlines,
            filter
          )}
      </div>
    );
  }
}

export default connect(
  state => ({
    filter: state.flights.searches[state.flights.activeSearchKey].filter.values,
    edges: {
      ...cloneDeep(
        state.flights.searches[state.flights.activeSearchKey].filter.edges
      )
    },
    activeSearchKey: state.flights.activeSearchKey
  }),
  dispatch => ({
    updateFilterValue: (key, field, value, param = null, operation) =>
      dispatch(updateFlightsFilter(key, field, value, param, operation))
  })
)(FilterBox);
