import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import Modal from "../../../_commons/Modal";
import Balloon from "../../../_commons/Balloon";
import TravellersOption from "./TravellersOption/index.jsx";
import ComboboxCabinClass from "../ComboboxCabinClass";
import { toggle, crementTraveller } from "../../../../redux/actions";
import { getLabel, getTravellersText } from "../../../../utils/functions";
import cx from "classnames";
require("./style.scss");

const types = {
  adt: {
    name: getLabel("adults"),
    age: getLabel("adults.age")
  },
  ch: {
    name: getLabel("children"),
    age: getLabel("children.age")
  },
  inf: {
    name: getLabel("babies"),
    age: getLabel("babies.age")
  }
};

const getAmount = ({ adt, ch, inf }) => {
  if (!ch && !inf) return 1;
  if (!ch || !inf) return 2;
  return 3;
};

const ComboboxTravellers = ({
  isVisible,
  travellers,
  cabinClass,
  onToggle,
  crementTraveller
}) => {
  const { adt, ch, inf } = travellers;
  const disabled = adt + ch + inf >= 9;
  return (
    <div className="comboboxTravellers">
      <div className={cx("combobox", { opened: isVisible })} onClick={onToggle}>
        <div className={cx("visible", "amount--" + getAmount(travellers))}>
          {getTravellersText(travellers)}, {getLabel("class." + cabinClass)}
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={isVisible ? faCaretUp : faCaretDown} />
        </div>
      </div>
      {isVisible && (
        <>
          <Balloon>
            {Object.keys(types).map(type => (
              <TravellersOption
                key={type}
                type={type}
                {...types[type]}
                onUpdate={crementTraveller}
                value={travellers[type]}
                disabled={disabled}
              />
            ))}
            <ComboboxCabinClass />
          </Balloon>
          <Modal
            className="comboboxTravellers__modal"
            isOpen
            onRequestClose={onToggle}
            contentLabel="Travellers"
            portalClassName="comboboxTravellers__portal"
          >
            <div className="comboboxTravellers__modal__content">
              {Object.keys(types).map(type => (
                <TravellersOption
                  key={type}
                  type={type}
                  {...types[type]}
                  onUpdate={crementTraveller}
                  value={travellers[type]}
                  disabled={disabled}
                />
              ))}
              <ComboboxCabinClass />
              <button onClick={onToggle}>{getLabel("submit")}</button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default connect(
  state => ({
    isVisible: state.toggles.flightsSearchboxComboboxTravellers,
    travellers: state.flights.searchbox.travellers,
    cabinClass: state.flights.searchbox.cabinClass.code
  }),
  dispatch => ({
    onToggle: event => {
      event.stopPropagation();
      dispatch(toggle("flightsSearchboxComboboxTravellers"));
    },
    crementTraveller: (traveller, value, event) => {
      event.stopPropagation();
      dispatch(crementTraveller(traveller, value));
    }
  })
)(ComboboxTravellers);
