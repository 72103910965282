import React, { Component } from "react";
import Header from "../_commons/Header/index.jsx";
import RedirectDetails from "./RedirectDetails/index.jsx";
import { renderMeta } from "../../../utils/functions";

export default class RedirectPage extends Component {
  componentDidMount() {
    const [, url] = this.props.location.search.split("?url=");
    setTimeout(() => (document.location.href = decodeURIComponent(url)), 2000);
  }
  render() {
    return (
      <div>
        {renderMeta("redirect")}
        <Header />
        <RedirectDetails {...this.props.match.params} />
      </div>
    );
  }
}
