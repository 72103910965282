import React from "react";
import { connect } from "react-redux";
import FlightResult from "../FlightResult/index.jsx";
import SortingBar from "../../_commons/SortingBar";
import ResultsCounter from "../../_commons/ResultsCounter";
import ResultsLoaderBar from "../../_commons/ResultsLoaderBar";
import config from "../../../../config";
require("./style.scss");

const SHOPS_COUNT = config.shops.length;

const FlightResults = ({
  results,
  paging,
  responsesCounter,
  filteredResults
}) => {
  const { page, perPage } = paging;
  const start = page * perPage;
  const end = Math.min((page + 1) * perPage, filteredResults.length);
  return (
    <>
      <div className="flightResultsHeader">
        <ResultsCounter
          results={filteredResults.length}
          total={responsesCounter < SHOPS_COUNT ? -1 : results.length}
        />
        <SortingBar />
      </div>
      <ResultsLoaderBar />
      <div className="flightResults">
        {filteredResults.slice(start, end).map((fare, index) => (
          <FlightResult key={`${fare.key}-${fare.shops.length}`} fare={fare} />
        ))}
      </div>
    </>
  );
};

export default connect(state => ({
  results: state.flights.searches[state.flights.activeSearchKey].results,
  paging: state.flights.searches[state.flights.activeSearchKey].paging,
  responsesCounter:
    state.flights.searches[state.flights.activeSearchKey].responsesCounter
}))(FlightResults);
