import React from "react";
import { Link } from "react-router-dom";
import MenuMain from "./MenuMain/index.jsx";
// import MenuTop from "./MenuTop/index.jsx";
import MenuLocalization from "./MenuLocalization/index.jsx";
import config from "../../../../config";
import cx from "classnames";
require("./style.scss");

const { brand } = config;

const D = document,
  B = D.body,
  DE = D.documentElement;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      documentHeight: 0
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      visible: !this.state.visible,
      documentHeight:
        Math.max(
          DE["clientHeight"],
          B["scrollHeight"],
          DE["scrollHeight"],
          B["offsetHeight"],
          DE["offsetHeight"]
        ) - 50
    });
  }

  render() {
    return (
      <div className="header">
        <Link to={"/"} className="link" title={brand}>
          <img
            src={require("../../../../static/images/icons/logotravelio.jpg")}
            alt={brand}
          />
        </Link>
        <div
          className={cx("burger", { opened: this.state.visible })}
          onClick={this.onClick}
        >
          <img
            src={require("../../../../static/images/icons/" +
              (this.state.visible ? "close" : "burger") +
              ".png")}
            alt="menu"
          />
        </div>
        <div
          className={cx("wrapMenus", { visible: this.state.visible })}
          style={{ height: this.state.documentHeight }}
        >
          <div className="menus">
            <MenuMain />
            {/* <MenuTop /> // FIXME */}
            <MenuLocalization /> 
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
