import React from "react";
import { connect } from "react-redux";
import cx from "classnames";
import config from "../../../../config";
require("./style.scss");

const { shops } = config;
const total = shops.length;

class ResultsLoaderBar extends React.Component {
  render() {
    const { responsesCounter } = this.props;
    return (
      <div className="resultsLoader">
        <div
          className={cx("loaderBar", {
            "loaderBar-loaded": responsesCounter === total
          })}
        />
      </div>
    );
  }
}

export default connect(state => ({
  responsesCounter:
    state.flights.searches[state.flights.activeSearchKey].responsesCounter
}))(ResultsLoaderBar);
