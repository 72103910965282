import React from "react";
import Loader from "../../../_commons/Loader";
import { getLabel } from "../../../../utils/functions";
require("./style.scss");

export default ({ results, total }) => (
  <div className="ResultsCounter">
    {getLabel("results")}:{" "}
    <span className="counter">
      {results}/{total === -1 ? <Loader small /> : total}
    </span>
  </div>
);
