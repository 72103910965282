import React from "react";
import { Provider } from "react-redux";
import {
  HashRouter,
  // BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// import { syncHistoryWithStore } from "react-router-redux";
import store from "./redux/store/index.js";
import Page from "./components/_commons/Page";
import routes from "./routing";

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={props =>
        route.skipPageWrapper ? (
          <route.component {...props} routes={route.routes} />
        ) : (
          <Page>
            <route.component {...props} routes={route.routes} />
          </Page>
        )
      }
    />
  );
}

function App() {
  return (
    <Provider store={store}>
      <HashRouter
      // history={h}
      // routes={routes}
      // render={applyRouterMiddleware(useScroll())}
      >
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </HashRouter>
    </Provider>
  );
}

export default App;
