import React from "react";
import PanelAside from "./PanelAside/index.jsx";
import PanelMain from "./PanelMain/index.jsx";
import cx from "classnames";

class PageAsides extends React.Component {
  render() {
    return (
      <div className={cx("page", "pageContent", this.props.kind)}>
        <PanelAside>{this.props.aside}</PanelAside>
        <PanelMain>{this.props.main}</PanelMain>
      </div>
    );
  }
}

export default PageAsides;
