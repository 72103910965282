import React from "react";
import { getStopsLabel } from "../../../../utils/functions";
require("./style.scss");

export default ({ stops, duration }) => (
  <div className="StopsBar">
    <div className="label">
      <span className="stops">{getStopsLabel(stops)}</span>
      <span className="dur">{duration}</span>
    </div>
    {Array.from(Array(stops)).map((_, idx) => (
      <div
        key={idx}
        className="dot"
        style={{ left: `${((idx + 1) * 100) / (stops + 1)}%` }}
      />
    ))}
  </div>
);
