import React from "react";
require("./style.scss");

export default ({ checked, onChange, type = "checkbox", children }) => {
  let element;
  const id = type + "-" + Math.random();
  return (
    <div className="checkbox">
      <input
        type={type}
        id={id}
        ref={node => (element = node)}
        checked={checked}
        onChange={() => onChange(element.checked)}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};
