import React from "react";
import { Link } from "react-router-dom";
import ContentPage from "../ContentPage/index.jsx";
import {
  getLabel,
  getLocalization,
  validateEmail
} from "../../utils/functions";
import config from "../../config";
require("./style.scss");

const { routes } = config;

class ContentPageContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      sent: false
    };
    this.name = null;
    this.email = null;
    this.message = null;
  }

  isValid = data => {
    let valid = true;
    if (data.name === "") {
      valid = false;
      this.setState({ name: getLabel("empty.name") });
    }
    if (data.email === "") {
      valid = false;
      this.setState({ email: getLabel("empty.email.field") });
    } else if (!validateEmail(data.email)) {
      valid = false;
      this.setState({ email: getLabel("invalid.email.field") });
    }
    if (data.message === "") {
      valid = false;
      this.setState({ message: getLabel("empty.message") });
    }
    return valid;
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ name: "", email: "", message: "" });
    const data = {
      name: this.name.value.trim(),
      email: this.email.value.trim(),
      message: this.message.value.trim()
    };
    if (!this.isValid(data)) return;
    this.setState({ sent: true });
    setTimeout(() => {
      this.setState({ sent: false });
    }, 5000);
    // console.log({ data }); // FIXME
    this.name.value = "";
    this.email.value = "";
    this.message.value = "";
  };

  render() {
    const lang = getLocalization().language;
    const faqPath = routes.filter(item => item.type === "faq")[0].labels[lang];
    const { name, email, message, sent } = this.state;
    return (
      <ContentPage page={this.props.match.path}>
        <div className="contentPageContact">
          <h1>{getLabel("contact")}</h1>
          <div className="content">
            <h2>
              {getLabel("have.you.tried.faq", {
                faq: (
                  <Link to={faqPath}>
                    {getLabel("frequently.asked.questions")}
                  </Link>
                )
              })}
            </h2>
            <form onSubmit={this.handleSubmit}>
              {name !== "" && <div className="invalid">{name}</div>}
              <input
                ref={r => {
                  this.name = r;
                }}
                type="text"
                placeholder={getLabel("your.name")}
              />
              {email !== "" && <div className="invalid">{email}</div>}
              <input
                ref={r => {
                  this.email = r;
                }}
                type="text"
                placeholder={getLabel("your.email")}
              />
              {message !== "" && <div className="invalid">{message}</div>}
              <textarea
                ref={r => {
                  this.message = r;
                }}
                placeholder={getLabel("your.message")}
              ></textarea>
              <button>{getLabel("send")}</button>
              {sent && (
                <div className="sent">{getLabel("message.has.been.sent")}</div>
              )}
            </form>
          </div>
        </div>
      </ContentPage>
    );
  }
}

export default ContentPageContact;
