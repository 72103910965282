import React from "react";
import Modal from "react-modal";
require("./style.scss");

const disableScroll = () =>
  (document.getElementsByTagName("body")[0].style.overflow = "hidden");

const enableScroll = () =>
  (document.getElementsByTagName("body")[0].style.overflow = "visible");

export default ({ children, portalClassName, ...props }) => (
  <Modal
    ariaHideApp={false}
    onAfterOpen={disableScroll}
    onAfterClose={enableScroll}
    {...props}
    portalClassName={portalClassName}
  >
    <div className="wrapper">{children}</div>
  </Modal>
);
