import React from "react";
import cx from "classnames";
import Loader from "../../../_commons/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { getTime, formatDuration, getLabel } from "../../../../utils/functions";
require("./style.scss");

const getSegment = (
  seg,
  stopover,
  flightNumber,
  airlineOperating,
  isWaiting
) => (
  <div className={cx("segment", { arrival: !flightNumber })}>
    <div className="time">
      <div className="hour">{getTime(seg.time)}</div>
      <div className="date">{getTime(seg.time, "ddd D/M/YYYY")}</div>
    </div>
    <div className="graphics">
      {flightNumber && <Loader plain />}
      {!flightNumber && (
        <FontAwesomeIcon
          className="pin"
          icon={isWaiting ? faCircle : faMapMarkerAlt}
        />
      )}
    </div>
    <div className="flight">
      <div className="name">{seg.name}</div>
      <div className="details">
        <div>
          {seg.name} {seg.name2} ({seg.code}), {seg.countryCode}
        </div>
        {flightNumber && (
          <div className="carrier">
            <strong>{getLabel("flight")}:</strong> {airlineOperating.name} /{" "}
            {airlineOperating.code} {flightNumber}
          </div>
        )}
        {flightNumber && stopover && (
          <div className="stopover">{getLabel("stopover.info")}</div>
        )}
      </div>
    </div>
    <div className="airline">
      {airlineOperating && (
        <img
          src={`https://s3-eu-west-1.amazonaws.com/media.tix.nl/travelio/${airlineOperating.code}.gif`}
          alt={airlineOperating.name}
        />
      )}
    </div>
  </div>
);

const getWaitingSegment = waitingDuration => (
  <div className="segment">
    <div className="time"> </div>
    <div className={cx("graphics", "waiting")}></div>
    <div className="flight">
      <div className="waiting">
        {getLabel("waiting.time")}:{" "}
        <strong>{formatDuration(waitingDuration)}</strong>
      </div>
    </div>
    <div className="airline"></div>
  </div>
);

class SegmentDetails extends React.Component {
  render() {
    const { segment, waitingDuration } = this.props;
    const { dep, arr, flightNumber, airlineOperating, stopover } = segment;
    return (
      <div className="segmentDetails">
        {getSegment(dep, stopover, flightNumber, airlineOperating)}
        {getSegment(arr, stopover, null, null, waitingDuration > 0)}
        {waitingDuration > 0 && getWaitingSegment(waitingDuration)}
      </div>
    );
  }
}

export default SegmentDetails;
