import uniq from "lodash/uniq";
import config from "../../../config";
import {
  api,
  getTime,
  getMatchedAirports,
  resetFlightsFilter,
  getLabel,
  getFlightsCriteriaKey
} from "../../../utils/functions";

const { shops } = config;

export const refillSearchbox = async props => {
  const newSearchbox = {};
  const {
    match: { params },
    updateTravellers,
    updateCabinClass,
    updateIsOneway,
    updateAirportsNearby,
    updateDate,
    updateAirport
  } = props;
  const {
    departureCode,
    destinationCode,
    travellers,
    departureDate,
    returnDate,
    departureIsNearby,
    destinationIsNearby
  } = params;
  const airports = await getMatchedAirports(departureCode, destinationCode);
  newSearchbox.departure = airports[0];
  updateAirport(
    "departure",
    newSearchbox.departure.value,
    newSearchbox.departure
  );
  newSearchbox.destination = airports[1];
  updateAirport(
    "destination",
    newSearchbox.destination.value,
    newSearchbox.destination
  );
  newSearchbox.departureDate = departureDate.replace(/-/g, "/");
  updateDate("departure", newSearchbox.departureDate);
  newSearchbox.returnDate =
    returnDate === "-" ? "" : returnDate.replace(/-/g, "/");
  updateDate("return", newSearchbox.returnDate);
  let [adt, ch, inf] = travellers.split("-");
  adt = +adt;
  ch = +ch;
  inf = +inf;
  if (
    !(
      adt >= 1 &&
      adt <= 9 &&
      ch >= 0 &&
      ch <= 9 &&
      inf >= 0 &&
      inf <= 9 &&
      inf <= adt &&
      adt + ch + inf <= 9
    )
  ) {
    return;
  }
  newSearchbox.travellers = { adt, ch, inf };
  updateTravellers(newSearchbox.travellers);
  let { cabinClass } = params;
  cabinClass = cabinClass.toUpperCase();
  const cabinClassName = getLabel(`class.${cabinClass}`);
  if (!cabinClassName) {
    return;
  }
  newSearchbox.cabinClass = { code: cabinClass, name: cabinClassName };
  updateCabinClass(newSearchbox.cabinClass);
  newSearchbox.isOneway = returnDate === "-";
  updateIsOneway(newSearchbox.isOneway);
  newSearchbox.departure.isNearby = departureIsNearby === "1";
  updateAirportsNearby("departure", newSearchbox.departure.isNearby);
  newSearchbox.destination.isNearby = destinationIsNearby === "1";
  updateAirportsNearby("destination", newSearchbox.destination.isNearby);
  return newSearchbox;
};

const onSearchCompleted = () => {};

const generateSegmentsKey = segments =>
  segments.map(
    segment =>
      segment.departureAirportCode +
      "-" +
      segment.arrivalAirportCode +
      "-" +
      segment.carrier +
      segment.flightNumber.toString().replace(/ /g, "")
  );

const generateFareKey = fare =>
  [
    ...generateSegmentsKey(fare.outbounds),
    ...(fare.inbounds ? generateSegmentsKey(fare.inbounds) : [])
  ].join("|");

const searches = {
  timestamp: 0
};

const onSearchResponse = (timestamp, shop, response, props, isOneway) => {
  const {
    pushResults,
    increaseResponseCounter,
    updateFilterEdges,
    updateFilterValue,
    edges,
    searchbox
  } = props;
  if (timestamp !== searches.timestamp) return;
  const airlinesMain = uniq(
    response.reduce((aggr, fare) => [...aggr, ...fare.airlines.main], [])
  );
  response.forEach(fare => {
    fare.key = generateFareKey(fare);
    fare.cabinClass = searchbox.cabinClass;
    fare.shops = [
      Object.assign({}, shop, { price: fare.price, url: fare.url })
    ];
    edges.price.min = Math.min(edges.price.min, Math.ceil(fare.price));
    edges.price.max = Math.max(edges.price.max, Math.ceil(fare.price));
    edges.outbound.duration.min = Math.min(
      edges.outbound.duration.min,
      Math.floor(fare.durations.outbound / 5) * 5
    );
    edges.outbound.duration.max = Math.max(
      edges.outbound.duration.max,
      Math.ceil(fare.durations.outbound / 5) * 5
    );
    if (fare.durations.inbound) {
      edges.inbound.duration.min = Math.min(
        edges.inbound.duration.min,
        Math.floor(fare.durations.inbound / 5) * 5
      );
      edges.inbound.duration.max = Math.max(
        edges.inbound.duration.max,
        Math.ceil(fare.durations.inbound / 5) * 5
      );
    }
    let stops = fare.outbounds.length - 1;
    const depO = +getTime(fare.outbounds[0].dep.time, "X");
    const dep0Floor = Math.floor(depO / 300) * 300;
    const dep0Ceil = Math.ceil(depO / 300) * 300;
    const arrO = +getTime(
      fare.outbounds[fare.outbounds.length - 1].arr.time,
      "X"
    );
    const arr0Floor = Math.floor(arrO / 300) * 300;
    const arr0Ceil = Math.ceil(arrO / 300) * 300;
    if (dep0Floor < edges.outbound.departure.min)
      edges.outbound.departure.min = dep0Floor;
    if (dep0Ceil > edges.outbound.departure.max)
      edges.outbound.departure.max = dep0Ceil;
    if (arr0Floor < edges.outbound.arrival.min)
      edges.outbound.arrival.min = arr0Floor;
    if (arr0Ceil > edges.outbound.arrival.max)
      edges.outbound.arrival.max = arr0Ceil;

    if (!isOneway) {
      stops = Math.max(stops, fare.inbounds.length - 1);
      const depI = +getTime(fare.inbounds[0].dep.time, "X");
      const depIFloor = Math.floor(depI / 300) * 300;
      const depICeil = Math.ceil(depI / 300) * 300;
      const arrI = +getTime(
        fare.inbounds[fare.inbounds.length - 1].arr.time,
        "X"
      );
      const arrIFloor = Math.floor(arrI / 300) * 300;
      const arrICeil = Math.ceil(arrI / 300) * 300;
      if (depIFloor < edges.inbound.departure.min)
        edges.inbound.departure.min = depIFloor;
      if (depICeil > edges.inbound.departure.max)
        edges.inbound.departure.max = depICeil;
      if (arrIFloor < edges.inbound.arrival.min)
        edges.inbound.arrival.min = arrIFloor;
      if (arrICeil > edges.inbound.arrival.max)
        edges.inbound.arrival.max = arrICeil;
    }
    if (stops === 0) {
      edges.stops.zero = Math.min(edges.stops.zero, fare.price);
    } else if (stops === 1) {
      edges.stops.one = Math.min(edges.stops.one, fare.price);
    } else if (stops === 2) {
      edges.stops.two = Math.min(edges.stops.two, fare.price);
    } else {
      edges.stops.more = Math.min(edges.stops.more, fare.price);
    }
    if (fare.airlines.inbound) {
      if (!edges.airlines[fare.airlines.inbound.code]) {
        edges.airlines[fare.airlines.inbound.code] = {
          price: fare.price,
          name: fare.airlines.inbound.name
        };
      } else {
        edges.airlines[fare.airlines.inbound.code].price = Math.min(
          edges.airlines[fare.airlines.inbound.code].price,
          fare.price
        );
      }
    }
    if (!edges.airlines[fare.airlines.outbound.code]) {
      edges.airlines[fare.airlines.outbound.code] = {
        price: fare.price,
        name: fare.airlines.outbound.name
      };
    } else {
      edges.airlines[fare.airlines.outbound.code].price = Math.min(
        edges.airlines[fare.airlines.outbound.code].price,
        fare.price
      );
    }
    if (config.showIntermediateAirports) {
      [...fare.outbounds, ...(fare.inbounds || [])]
        .filter(({ carrier }) => !airlinesMain.includes(carrier))
        .forEach(({ airlineOperating }) => {
          if (!edges.airlines[airlineOperating.code]) {
            edges.airlines[airlineOperating.code] = {
              price: fare.price,
              name: airlineOperating.name
            };
          } else {
            edges.airlines[airlineOperating.code].price = Math.min(
              edges.airlines[airlineOperating.code].price,
              fare.price
            );
          }
        });
    }
    if (!edges.shops[fare.shop]) {
      edges.shops[fare.shop] = {
        price: fare.price,
        name: fare.shop
      };
    } else {
      edges.shops[fare.shop].price = Math.min(
        edges.shops[fare.shop].price,
        fare.price
      );
    }
  });
  const key = getFlightsCriteriaKey(searchbox);
  pushResults(response, searchbox);
  increaseResponseCounter(searchbox);
  updateFilterEdges(edges, key);
  resetFlightsFilter(key, updateFilterValue, edges, isOneway);
};

export const makeSearch = props => {
  const {
    searchbox,
    resetFlightsResults,
    resetFlightsResponseCounter,
    setFlightsCriteria
  } = props;
  resetFlightsResults();
  resetFlightsResponseCounter();
  searchbox.isFilled = false;
  setFlightsCriteria(searchbox);
  const {
    departure: { code: departureCode },
    destination: { code: arrivalCode },
    departureDate: depDate,
    returnDate: retDate,
    travellers: { adt: adults, ch: children, inf: infants },
    isOneway,
    cabinClass: { code: cabinClass }
  } = searchbox;
  const searchRequests = [];
  const timestamp = Date.now();
  searches.timestamp = timestamp;
  shops.map((shop, index) =>
    searchRequests.push(
      new Promise(resolve =>
        api("/search/flights", {
          shop: shop.code,
          departureCode,
          arrivalCode,
          departureDate: depDate
            .split("/")
            .reverse()
            .join("-"),
          ...(isOneway
            ? {}
            : {
                returnDepartureDate: retDate
                  .split("/")
                  .reverse()
                  .join("-")
              }),
          adults,
          children,
          infants,
          cabinClass
        })
          .then(response => {
            resolve(
              onSearchResponse(timestamp, shop, response, props, isOneway)
            );
          })
          .catch(response => {
            resolve(onSearchResponse(timestamp, shop, [], props, isOneway));
          })
      )
    )
  );
  Promise.all(searchRequests).then(response => onSearchCompleted());
};

const compare = (sorting, filterShops, a, b) => {
  let dir = 0;
  const { key, isAscending } = sorting;
  const priceA = a.shops.filter(({ code }) => filterShops.includes(code))[0]
    .price;
  const priceB = b.shops.filter(({ code }) => filterShops.includes(code))[0]
    .price;
  switch (key) {
    case "price":
      dir = priceA < priceB ? -1 : 1;
      break;
    case "travelDuration":
      if (a.durations.total < b.durations.total) dir = 1;
      if (a.durations.total > b.durations.total) dir = -1;
      break;
    case "airline":
      if (a.marketingCarrier.name < b.marketingCarrier.name) dir = -1;
      if (a.marketingCarrier.name > b.marketingCarrier.name) dir = 1;
      break;
    case "depOutbound":
      if (a.outbounds[0].dep.time < b.outbounds[0].dep.time) dir = -1;
      if (a.outbounds[0].dep.time > b.outbounds[0].dep.time) dir = 1;
      break;
    case "arrOutbound":
      if (
        a.outbounds[a.outbounds.length - 1].arr.time <
        b.outbounds[b.outbounds.length - 1].arr.time
      )
        dir = -1;
      if (
        a.outbounds[a.outbounds.length - 1].arr.time >
        b.outbounds[b.outbounds.length - 1].arr.time
      )
        dir = 1;
      break;
    case "depInbound":
      if (a.inbounds[0].dep.time < b.inbounds[0].dep.time) dir = -1;
      if (a.inbounds[0].dep.time > b.inbounds[0].dep.time) dir = 1;
      break;
    case "arrInbound":
      if (
        a.inbounds[a.inbounds.length - 1].arr.time <
        b.inbounds[b.inbounds.length - 1].arr.time
      )
        dir = -1;
      if (
        a.inbounds[a.inbounds.length - 1].arr.time >
        b.inbounds[b.inbounds.length - 1].arr.time
      )
        dir = 1;
      break;
    default:
      dir = 0;
  }
  if (!isAscending) dir *= -1;
  if (dir === 0) {
    dir = priceA < priceB ? -1 : 1;
  }
  return dir;
};

export const getFilteredResults = (results, filter, sorting) => {
  const filterAirlines = Object.keys(filter.airlines).filter(
    key => filter.airlines[key]
  );
  const filterShops = Object.keys(filter.shops).filter(
    key => filter.shops[key]
  );
  return results
    .filter(item => {
      if (item.price > filter.price) return false;
      if (item.durations.outbound > filter.outboundDuration) return false;
      let stops = item.outbounds.length - 1;
      const depO = +getTime(item.outbounds[0].dep.time, "X");
      const arrO = +getTime(
        item.outbounds[item.outbounds.length - 1].arr.time,
        "X"
      );
      if (depO < filter.departureOut.min || depO > filter.departureOut.max)
        return false;
      if (arrO < filter.arrivalOut.min || arrO > filter.arrivalOut.max)
        return false;
      if (item.inbounds) {
        if (item.durations.inbound > filter.inboundDuration) return false;
        stops = Math.max(stops, item.inbounds.length - 1);
        const depI = +getTime(item.inbounds[0].dep.time, "X");
        const arrI = +getTime(
          item.inbounds[item.inbounds.length - 1].arr.time,
          "X"
        );
        if (depI < filter.departureIn.min || depI > filter.departureIn.max)
          return false;
        if (arrI < filter.arrivalIn.min || arrI > filter.arrivalIn.max)
          return false;
      }
      if (filter.stops === "zero" && stops > 0) return false;
      if (filter.stops === "one" && stops > 1) return false;
      if (filter.stops === "two" && stops > 2) return false;
      if (filter.stops === "more" && stops > 3) return false;
      if (
        !item.airlines[filter.combinedAirlines ? "all" : "main"].some(code =>
          filterAirlines.includes(code)
        )
      )
        return false;
      if (!item.shops.some(({ code }) => filterShops.includes(code)))
        return false;
      return true;
    })
    .sort(compare.bind(this, sorting, filterShops));
};
