import React from "react";
import RCSlider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";

require("./style.scss");

class Slider extends React.Component {
  render() {
    const { range, ...props } = this.props;
    return (
      <div className="slider">
        {range ? <Range {...props} /> : <RCSlider {...props} />}
      </div>
    );
  }
}

export default Slider;
