import React from "react";
import { Link } from "react-router-dom";
import { getLabel } from "../../../../utils/functions";
import config from "../../../../config";
require("./style.scss");

const { brand } = config;

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="links">
          <div className="aside">
            <h3>
              {getLabel('contact.details')}
            </h3>
            <a href={getLabel('/contact')}>
              {getLabel('monday.to.friday')}
            </a>
            <div>09:00 - 17:00</div>
          </div>
          <div className="aside">
            <h3>
              {getLabel('services')}
            </h3>
            <Link to={'/'}>
              {getLabel('flights')}
            </Link>
            <a href={getLabel('/hotels')}>
              {getLabel('hotels')}
            </a>
            <a href={getLabel('/cars')}>
              {getLabel('cars')}
            </a>
            <a href={getLabel('/activities')}>
              {getLabel('activities')}
            </a>
          </div>
          <div className="aside">
            <h3>
              {getLabel('the.company')}
            </h3>
            <a href={getLabel('/about.us')}>
              {getLabel('about.us')}
            </a>
          </div>
          <div className="aside">
            <h3>
              {getLabel('customer.service')}
            </h3>
            <a href={getLabel('/terms.and.condition')}>
              {getLabel('terms.and.condition')}
            </a>
            <a href={getLabel('/privacy.policy')}>
              {getLabel('privacy.policy')}
            </a>
            <a href={getLabel('/cookie.preferences')}>
              {getLabel('cookie.preferences')}
            </a>
          </div>
        </div>
          <div className="bottom">
            <div className="in">
              <div>
                <img
                  className="logo"
                  src={require("../../../../static/images/icons/logotravelio.jpg")}
                  alt={brand}
                />
                  {getLabel('all.rights.reserved')} {(new Date()).getFullYear()}.
              </div>
              <img
                className="ssl"
                src={require("../../../../static/images/icons/ssl.png")}
                alt="Secure SSL Encryption"
              />
            </div>
          </div>
      </div>
    );
  }
}

export default Footer;
