import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import {
  toggle,
  setSorting,
  changeFlightsPage,
  toggleFlightsFilterVisible
} from "../../../../redux/actions";
import Balloon from "../../../_commons/Balloon/index.jsx";
import { getLabel } from "../../../../utils/functions";
import cx from "classnames";
require("./style.scss");

const sortOptions = [
  {
    key: "price",
    isAscending: true,
    name: getLabel("price"),
    dir: getLabel("cheap.to.expensive")
  },
  {
    key: "price",
    isAscending: false,
    name: getLabel("price"),
    dir: getLabel("expensive.to.cheap")
  },
  { key: "travelDuration", name: getLabel("travel.duration"), dir: "" },
  {
    key: "airline",
    isAscending: true,
    name: getLabel("airline"),
    dir: "A - Z"
  },
  {
    key: "airline",
    isAscending: false,
    name: getLabel("airline"),
    dir: "Z - A"
  },
  {
    key: "depOutbound",
    isAscending: true,
    name: getLabel("departure.outbound"),
    dir: getLabel("early.to.late")
  },
  {
    key: "depOutbound",
    isAscending: false,
    name: getLabel("departure.outbound"),
    dir: getLabel("late.to.early")
  },
  {
    key: "arrOutbound",
    isAscending: true,
    name: getLabel("arrival.outbound"),
    dir: getLabel("early.to.late")
  },
  {
    key: "arrOutbound",
    isAscending: false,
    name: getLabel("arrival.outbound"),
    dir: getLabel("late.to.early")
  },
  {
    key: "depInbound",
    isAscending: true,
    name: getLabel("departure.inbound"),
    dir: getLabel("early.to.late")
  },
  {
    key: "depInbound",
    isAscending: false,
    name: getLabel("departure.inbound"),
    dir: getLabel("late.to.early")
  },
  {
    key: "arrInbound",
    isAscending: true,
    name: getLabel("arrival.inbound"),
    dir: getLabel("early.to.late")
  },
  {
    key: "arrInbound",
    isAscending: false,
    name: getLabel("arrival.inbound"),
    dir: getLabel("late.to.early")
  }
];

class SortingBar extends React.Component {
  handleChooseOption(option) {
    const {
      props: { activeSearchKey, onToggle, setSorting }
    } = this;
    onToggle();
    setSorting(
      { key: option.key, isAscending: option.isAscending },
      activeSearchKey
    );
  }

  render() {
    const {
      sorting: { key, isAscending },
      noResults,
      isVisible,
      onToggle
    } = this.props;
    const sortOption = sortOptions.find(
      item => item.key === key && item.isAscending === isAscending
    );
    if (noResults) return null;
    return (
      <div className={cx("sortingBar", { visible: isVisible })}>
        <span className="choose">
          <div className="btn" onClick={onToggle}>
            <span>
              <span className="field">
                {getLabel("sorted.by")} {sortOption.name.toLowerCase()}
              </span>
              <span className="order">{sortOption.dir}</span>
            </span>
            <FontAwesomeIcon
              className="icon"
              icon={isVisible ? faAngleUp : faAngleDown}
            />
          </div>
          <Balloon>
            {sortOptions.map((item, index) => (
              <div
                className="sortOption"
                key={index}
                onClick={this.handleChooseOption.bind(this, item)}
              >
                <strong>{item.name.toLowerCase()}</strong>
                <span>{item.dir}</span>
              </div>
            ))}
          </Balloon>
        </span>
      </div>
    );
  }
}

export default connect(
  state => ({
    isVisible: state.toggles.flightsResultsPageSortingBar,
    sorting: state.flights.searches[state.flights.activeSearchKey].sorting,
    activeSearchKey: state.flights.activeSearchKey,
    noResults:
      state.flights.searches[state.flights.activeSearchKey].results.length === 0
  }),
  dispatch => ({
    onToggle: event => {
      if (event) event.stopPropagation();
      dispatch(toggle("flightsResultsPageSortingBar"));
    },
    onFilterToggle: () => dispatch(toggleFlightsFilterVisible()),
    setSorting: (sorting, key) => {
      dispatch(setSorting(sorting, key));
      dispatch(changeFlightsPage(0, key));
    }
  })
)(SortingBar);
