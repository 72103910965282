import React from "react";
import StopsBar from "../StopsBar";
import Balloon from "../../../_commons/Balloon";
import {
  getTime,
  formatDuration,
  getLabel,
  getArrivalNextDay
} from "../../../../utils/functions";
require("./style.scss");

const segmentDetails = (bounds, duration, airline) => {
  if (!bounds) return null;
  const departure = bounds[0];
  const destination = bounds[bounds.length - 1];
  const stops = bounds.length - 1;
  const arrivalDaysOffset = getArrivalNextDay(bounds);
  return (
    <div className="segment">
      <span className="airline">
        <img
          src={`https://s3-eu-west-1.amazonaws.com/media.tix.nl/travelio/${airline.code}.gif`}
          alt={airline.name}
        />
        <div className="airlineName">{airline.name}</div>
      </span>
      <div className="seg">
        <div className="takeoff">
          <span className="hour">{getTime(departure.dep.time)}</span>
          <span className="airport">{departure.dep.code}</span>
        </div>
        <div className="day">{getTime(departure.dep.time, "ddd, D MMM")}</div>
      </div>
      <StopsBar stops={stops} duration={formatDuration(duration)} />
      <div className="seg destination">
        <div className="takeoff">
          <span className="airport">{destination.arr.code}</span>
          <span className="hour">
            {getTime(destination.arr.time)}
            {arrivalDaysOffset && (
              <>
                <div className="offset">+1</div>
                <Balloon className="offsetDesc">
                  {getLabel("arrival.next.day")}
                </Balloon>
              </>
            )}
          </span>
        </div>
        <div className="day">{getTime(destination.arr.time, "ddd, D MMM")}</div>
      </div>
      <span className="duration">{formatDuration(duration)}</span>
    </div>
  );
};

export default ({ outbounds, inbounds, durations, airlines }) => (
  <div className="fare">
    <div className="details">
      <div className="segments">
        {segmentDetails(outbounds, durations.outbound, airlines.outbound)}
        {segmentDetails(inbounds, durations.inbound, airlines.inbound)}
      </div>
    </div>
  </div>
);
