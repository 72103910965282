import React from "react";
import Searchbox from "../Searchbox/Flights/index.jsx";
import Why from './why';
import { getLabel } from "../../utils/functions";
require("./style.scss");
class HomePage extends React.Component {
  render() {
    return (
      <div>
        <div className="page pageCentered homepage">
          <div className="box">
            <h1>{getLabel("find.the.cheapest.flight.tickets")}</h1>
            <Searchbox />
          </div>
        </div>
        <Why />
      </div>
    );
  }
}

export default HomePage;
