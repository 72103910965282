export default {
  brand: "Travelio",
  showIntermediateAirports: false,
  shops: [
    {
      code: "gate1.nl",
      name: "Gate1.nl"
    },
    // {
    //   code: "Flighttix.pl",
    //   name: "Flighttix.pl"
    // },
    {
      code: "Flighttix.de",
      name: "Flighttix.de"
    },
    {
      code: "Tix.fr",
      name: "Tix.fr"
    },
    {
      code: "kiwi.com",
      name: "kiwi.com"
    }
  ],
  languages: {
    NL: {
      name: "Nederlands",
      isDefault: true,
      defaultCountry: "NL",
      defaultCurrency: "EUR"
    },
    // PL: {
    //   name: "Polski",
    //   defaultCountry: "PL",
    //   defaultCurrency: "PLN"
    // },
    EN: {
      name: "English",
      defaultCountry: "US",
      defaultCurrency: "USD"
    },
    // IT: {
    //   name: "Italian",
    //   defaultCountry: "IT",
    //   defaultCurrency: "EUR"
    // },
    // DE: {
    //   name: "German",
    //   defaultCountry: "DE",
    //   defaultCurrency: "EUR"
    // }
  },
  countries: ["NL", "PL", "US", "IT", "DE"],
  currencies: {
    EUR: {
      symbol: "€"
    },
    PLN: {
      symbol: "zł"
    },
    USD: {
      symbol: "$"
    }
  },
  cabinClasses: [
    "ECONOMY",
    "PREMIUM_ECONOMY",
    "BUSINESS",
    "PREMIUM_FIRST",
    "FIRST"
  ],
  competitors: ["gate1.nl", "Flighttix.pl", "Flighttix.de", "Tix.fr"],
  routes: [
    // {
    //   type: "general",
    //   inPages: true,
    //   inFooter: false,
    //   labels: {
    //     EN: "/about-us",
    //     PL: "/o-nas"
    //   }
    // },
    // {
    //   type: "faq",
    //   inPages: true,
    //   inFooter: false,
    //   labels: {
    //     EN: "/faq",
    //     PL: "/faq"
    //   }
    // },
    // {
    //   type: "contact",
    //   inPages: true,
    //   inFooter: true,
    //   labels: {
    //     EN: "/contact",
    //     PL: "/kontakt"
    //   }
    // },
    // {
    //   type: "general",
    //   inPages: true,
    //   inFooter: true,
    //   labels: {
    //     EN: "/terms-of-service",
    //     PL: "/zasady-uzytkowania"
    //   }
    // },
    // {
    //   type: "general",
    //   inPages: true,
    //   inFooter: true,
    //   labels: {
    //     EN: "/privacy-policy",
    //     PL: "/polityka-prywatnosci"
    //   }
    // },
    // {
    //   type: "general",
    //   inPages: false,
    //   inFooter: true,
    //   labels: {
    //     EN: "/cookies",
    //     PL: "/cookies"
    //   }
    // }
  ]
};
