import React from "react";
import { connect } from "react-redux";
import Checkbox from "../../_commons/Checkbox";
import { updateSearchboxOneway } from "../../../../redux/actions";
import { getLabel } from "../../../../utils/functions";

const OnewaySwitch = ({ isOneway, onSwitchClick }) => (
  <div className="tabs">
    <Checkbox
      type="radio"
      checked={!isOneway}
      onChange={() => onSwitchClick(false)}
    >
      {getLabel("return.flight")}
    </Checkbox>
    <Checkbox
      type="radio"
      checked={isOneway}
      onChange={() => onSwitchClick(true)}
    >
      {getLabel("one.way.flight")}
    </Checkbox>
  </div>
);

export default connect(
  state => ({ isOneway: state.flights.searchbox.isOneway }),
  dispatch => ({
    onSwitchClick: isOneway => dispatch(updateSearchboxOneway(isOneway))
  })
)(OnewaySwitch);
