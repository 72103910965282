import React from "react";
import { connect } from "react-redux";
import { toggle } from "../../../redux/actions";
import Header from "./Header/index.jsx";
import Footer from "./Footer/index.jsx";
import CookiesInfo from "./CookiesInfo/index.jsx";
require("./style.scss");

class Page extends React.Component {
  onPageClick(event) {
    this.props.onToggle();
  }

  render() {
    return (
      <div className="app" onClick={this.onPageClick.bind(this)}>
        <Header />
        {this.props.children} 
        <Footer />
        <CookiesInfo /> 
      </div>
    );
  }
}

export default connect(null, dispatch => ({
  onToggle: () => dispatch(toggle(""))
}))(Page);
