import { getFlightsCriteriaKey } from "../utils/functions";

export const updateSearchboxOneway = isOneway => ({
  type: "UPDATE_SEARCHBOX_ONEWAY",
  isOneway
});

export const toggleSearchboxCompetitor = (competitor, isChecked) => ({
  type: "TOGGLE_SEARCHBOX_COMPETITOR",
  competitor,
  isChecked
});

export const autocompleteAirportValueChanged = (direction, value) => ({
  type: "AUTOCOMPLETE_AIRPORT_VALUE_CHANGED",
  direction,
  value
});

export const autocompleteAirportSelected = (direction, value, airport) => ({
  type: "AUTOCOMPLETE_AIRPORT_SELECTED",
  direction,
  value,
  airport
});

export const autocompleteAirportsNearby = (direction, isNearby) => ({
  type: "AUTOCOMPLETE_AIRPORTS_NEARBY",
  direction,
  isNearby
});

export const autocompleteAirportSwitch = (direction, airport) => ({
  type: "AUTOCOMPLETE_AIRPORT_SWITCH",
  direction,
  airport
});

export const toggle = (what, item) => ({
  type: "TOGGLE",
  what,
  item
});

export const datepickerDateSelected = (field, date) => ({
  type: "DATEPICKER_DATE_SELECTED",
  field,
  date
});

export const updateTravellers = travellers => ({
  type: "UPDATE_TRAVELLERS",
  travellers
});

export const crementTraveller = (traveller, value) => ({
  type: "CREMENT_TRAVELLER",
  traveller,
  value
});

export const updateCabinClass = cabinClass => ({
  type: "UPDATE_CABINCLASS",
  cabinClass
});

export const validateFlightSearchbox = validations => ({
  type: "VALIDATE_SEARCHBOX",
  validations
});

export const setFlightsSearchboxFilled = isFilled => ({
  type: "SET_FLIGHTS_SEARCHBOX_FILLED",
  isFilled
});

export const pushFlightsResults = (results, criteria) => ({
  type: "PUSH_FLIGHTS_RESULTS",
  results,
  criteria
});

export const resetFlightsResults = () => ({
  type: "RESET_FLIGHTS_RESULTS"
});

export const increaseFlightsResponseCounter = criteria => ({
  type: "INCREASE_FLIGHTS_RESPONSE_COUNTER",
  criteria
});

export const resetFlightsResponseCounter = () => ({
  type: "RESET_FLIGHTS_RESPONSE_COUNTER"
});

export const changeFlightsPage = (page, key) => ({
  type: "CHANGE_FLIGHTS_PAGE",
  page,
  key
});

export const creaseFlightsPage = (step, key) => ({
  type: "CREASE_FLIGHTS_PAGE",
  step,
  key
});

export const updateFlightsFilterEdges = (edges, key) => ({
  type: "UPDATE_FLIGHTS_FILTER_EDGES",
  edges,
  key
});

export const setSorting = (sorting, key) => ({
  type: "SET_FLIGHTS_SORTING",
  sorting,
  key
});

export const updateFlightsFilter = (
  key,
  field,
  value,
  param = null,
  operation
) => ({
  type: "UPDATE_FLIGHTS_FILTER",
  key,
  field,
  value,
  param,
  operation
});

export const setFlightsCriteria = criteria => ({
  type: "SET_FLIGHTS_CRITERIA",
  criteria: {
    ...criteria,
    key: getFlightsCriteriaKey(criteria)
  }
});

export const toggleFlightsFilterVisible = () => ({
  type: "TOGGLE_FLIGHTS_FILTER_VISIBLE"
});

export const setFlightsActiveSearchKey = key => ({
  type: "SET_FLIGHTS_ACTIVE_SEARCH_KEY",
  key
});

export const removeFlightsSearchKey = key => ({
  type: "REMOVE_FLIGHTS_SEARCH_KEY",
  key
});
