import React from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane } from "@fortawesome/free-solid-svg-icons";
require("./style.scss");

export default ({ small = false, gray = false, plain = false, className }) => (
  <div className={cx("loader", className, { small, gray, plain })}>
    <FontAwesomeIcon icon={faPlane} className="icon" />
  </div>
);
