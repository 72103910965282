import React, {} from 'react';
import { getLabel } from "../../../utils/functions";
require("./style.scss");

class Why extends React.Component {
    render() {
        return (
            <div className="why">
                <div className="in">
                    <h3>{getLabel('why.travelio')}</h3>
                    <div className="sections">
                        <div className="section">
                            <img
                                src={require("../../../static/images/icons/gear.png")}
                                alt={getLabel('why.travelio.title.1')}
                            />
                            <h4>{getLabel('why.travelio.title.1')}</h4>
                            <div>
                                {getLabel('why.travelio.desc.1')}
                            </div>
                        </div>
                        <div className="section">
                            <img
                                src={require("../../../static/images/icons/secure.png")}
                                alt={getLabel('why.travelio.title.2')}
                            />
                            <h4>{getLabel('why.travelio.title.2')}</h4>
                            <div>
                                {getLabel('why.travelio.desc.2')}
                            </div>
                        </div>
                        <div className="section">
                            <img
                                src={require("../../../static/images/icons/pig.png")}
                                alt={getLabel('why.travelio.title.3')}
                            />
                            <h4>{getLabel('why.travelio.title.3')}</h4>
                            <div>
                                {getLabel('why.travelio.desc.3')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
} 

export default Why;