import React from "react";
import SegmentDetails from "../SegmentDetails/index.jsx";
import {
  formatDuration,
  getLabel,
  getTime,
  getStopsLabel,
  getArrivalNextDay
} from "../../../../utils/functions";
require("./style.scss");

const getWaitingDuration = (curr, next) => {
  if (!next) return 0;
  return (
    (new Date(next.departureDateTime).getTime() -
      new Date(curr.arrivalDateTime).getTime()) /
    60000
  );
};

class BoundDetails extends React.Component {
  render() {
    const { bounds, duration, cabinClass } = this.props;
    if (!bounds) return null;
    const arrivalNextDay = getArrivalNextDay(bounds);
    return (
      <div className="boundDetails">
        <div className="boundTitle">
          {bounds[0].dep.name} - {bounds[bounds.length - 1].arr.name}
        </div>
        <div className="boundDesc">
          <div>
            {getTime(bounds[0].dep.time, "D MMM YYYY")}
            {arrivalNextDay && (
              <span>
                {" "}
                -{" "}
                <span className="warning">{getLabel("arrival.next.day")}</span>
              </span>
            )}
          </div>
          <div>
            {getLabel("total.duration.time")}: {formatDuration(duration)},{" "}
            {getStopsLabel(bounds.length - 1)}, {cabinClass.name}
          </div>
        </div>
        {bounds.map((segment, index) => (
          <SegmentDetails
            key={index}
            segment={segment}
            cabinClass={cabinClass}
            waitingDuration={getWaitingDuration(segment, bounds[index + 1])}
            arrivalNextDay={arrivalNextDay}
          />
        ))}
      </div>
    );
  }
}

export default BoundDetails;
