import React from "react";
import Checkbox from "../../../../Searchbox/_commons/Checkbox/index.jsx";
import { getLocalization, getLabel } from "../../../../../utils/functions";
import config from "../../../../../config";
import cx from "classnames";
require("./style.scss");

class MenuLocalization extends React.Component {
  constructor(props) {
    super(props);
    const { language, country, currency } = getLocalization();
    this.state = {
      menus: false,
      languages: true,
      countries: false,
      currencies: false,
      language,
      country,
      currency
    };
  }

  toggle(menu) {
    const state = {
      languages: true,
      countries: false,
      currencies: false
    };
    state[menu] = !this.state[menu];
    this.setState(state);
  }

  handleChoose = (what, key) => () => {
    if (typeof Storage !== "undefined") {
      if (localStorage[what] === key) return;
      localStorage[what] = key;
      this.setState({ [what]: key });
      if (window) {
        window.document.location.reload();
      }
    } else {
      // FIXME
    }
  };

  getLanguages(map) {
    const items = [];
    const { language } = this.state;
    for (let key in map) {
      items.push(
        <Checkbox
          key={key}
          checked={key === language}
          onChange={this.handleChoose("language", key)}
          type="radio"
        >
          <div className="item-country">
          <img
            src={require("../../../../../static/images/icons/flag-" +
              key +
              ".png")}
            alt={key}
          />
            <span>{map[key].name}</span>
          </div>
        </Checkbox>
      );
    }
    return items;
  }

  getCountries(arr) {
    const items = [];
    const { country } = this.state;
    arr.forEach(key => {
      items.push(
        <Checkbox
          key={key}
          checked={key === country}
          onChange={this.handleChoose("country", key)}
          type="radio"
        >
          <div className="item-country">
            <img
              src={require("../../../../../static/images/icons/flag-" +
                key +
                ".png")}
              alt={key}
            />
            <span>{getLabel(`country.${key.toLowerCase()}`)}</span>
          </div>
        </Checkbox>
      );
    });
    return items;
  }

  getCurrencies(map) {
    const items = [];
    const { currency } = this.state;
    for (let key in map) {
      items.push(
        <Checkbox
          key={key}
          checked={key === currency}
          onChange={this.handleChoose("currency", key)}
          type="radio"
        >
          {key} - {map[key].symbol}
        </Checkbox>
      );
    }
    return items;
  }

  render() {
    const { languages, countries, currencies } = config;
    const { language, country, currency } = this.state;
    return (
      <div className="menuLocalization">
        <div
          className={cx("item", "combo", "item-burger", {
            ["opened"]: this.state.menus
          })}
          onClick={this.toggle.bind(this, "menus")}
        >
          <span className="service">
            <span>
              <a href={getLabel('/contact')}>
                <span>
                  <img
                    src={require("../../../../../static/images/icons/customer-support.png")}
                  />
                </span>
                {getLabel('customer.service')}
              </a>
            </span>
          </span>
          <span>
            <img
              src={require("../../../../../static/images/icons/flag-" +
              language +
                ".png")}
            />
          </span>
          <span>{languages[language].name}</span>
        </div>
        <div
          className={cx("options", "all-options", {
            ["visible"]: this.state.menus
          })}
        >
          <div className="all-options-in">
            {/* <div
              className={cx("item", "combo", {
                ["opened"]: this.state.languages
              })}
              onClick={this.toggle.bind(this, "languages")}
            >
              {getLabel("language")}
            </div> */}
            <div
              className={cx("options", "submenu", {
                ["visible"]: this.state.languages
              })}
            >
              {this.getLanguages(languages)}
            </div>
            {/* <div
              className={cx("item", "combo", {
                ["opened"]: this.state.countries
              })}
              onClick={this.toggle.bind(this, "countries")}
            >
              {getLabel("country")}
            </div>
            <div
              className={cx("options", "submenu", {
                ["visible"]: this.state.countries
              })}
            >
              {this.getCountries(countries)}
            </div> */}
            {/* <div
              className={cx("item", "combo", {
                ["opened"]: this.state.currencies
              })}
              onClick={this.toggle.bind(this, "currencies")}
            >
              {getLabel("currency")}
            </div>
            <div
              className={cx("options", "submenu", {
                ["visible"]: this.state.currencies
              })}
            >
              {this.getCurrencies(currencies)}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default MenuLocalization;
