import React from "react";
import { Link } from "react-router-dom";
import ContentPage from "../ContentPage/index.jsx";
import { getLabel, getLocalization } from "../../utils/functions";
import config from "../../config";
import cx from "classnames";
require("./style.scss");

const { routes } = config;
const faq = [
  {
    question: "Vestibulum id viverra leo?",
    answer:
      "Nam pharetra magna nec enim rhoncus, vitae malesuada dolor tristique. In sit amet condimentum diam. Nulla lacinia, velit a commode fermentum, urna est viverra magna, nec bibendum elit lacus non sapien. Etiam at tincidunt metus, ac pretium arcu. Aliquam sodales, urna scelerisque pellentesque tempor, massa tortor fringilla augue, eu pretium lorem odio dignissim elit. Maecenas placerat ipsum ac tellus facilisis aliquam. Pellentesque a leo porttitor, molestie magna a, varius odio. Curabitur et lorem lobortis, aliquet ipsum a, molestie ante. Sed interdum id odio in mattis. Donec eget lectus purus. Mauris molestie ipsum felis, quis dictum leo posuere sit amet. Integer sagittis, ligula eu ultrices blandit, augue augue cursus quam, eget facilisis lectus elit vel eros. Integer pulvinar ante et tortor venenatis, ut lacinia ligula hendrerit."
  },
  {
    question: "Nam pharetra magna nec enim?",
    answer:
      "In sit amet condimentum diam. Nulla lacinia, velit a commode fermentum, urna est viverra magna, nec bibendum elit lacus non sapien. Etiam at tincidunt metus, ac pretium arcu. Aliquam sodales, urna scelerisque pellentesque tempor, massa tortor fringilla augue, eu pretium lorem odio dignissim elit. Maecenas placerat ipsum ac tellus facilisis aliquam. Pellentesque a leo porttitor, molestie magna a, varius odio. Curabitur et lorem lobortis, aliquet ipsum a, molestie ante. Sed interdum id odio in mattis. Donec eget lectus purus. Mauris molestie ipsum felis, quis dictum leo posuere sit amet. Integer sagittis, ligula eu ultrices blandit, augue augue cursus quam, eget facilisis lectus elit vel eros. Integer pulvinar ante et tortor venenatis, ut lacinia ligula hendrerit."
  },
  {
    question: "vitae malesuada dolor tristique?",
    answer:
      "Pellentesque a leo porttitor, molestie magna a, varius odio. Curabitur et lorem lobortis, aliquet ipsum a, molestie ante. Sed interdum id odio in mattis. Donec eget lectus purus. Mauris molestie ipsum felis, quis dictum leo posuere sit amet. Integer sagittis, ligula eu ultrices blandit, augue augue cursus quam, eget facilisis lectus elit vel eros. Integer pulvinar ante et tortor venenatis, ut lacinia ligula hendrerit."
  },
  {
    question: "Etiam at tincidunt metus?",
    answer:
      "Etiam at tincidunt metus, ac pretium arcu. Aliquam sodales, urna scelerisque pellentesque tempor, massa tortor fringilla augue, eu pretium lorem odio dignissim elit. Maecenas placerat ipsum ac tellus facilisis aliquam. Pellentesque a leo porttitor, molestie magna a, varius odio. Curabitur et lorem lobortis, aliquet ipsum a, molestie ante. Sed interdum id odio in mattis. Donec eget lectus purus. Mauris molestie ipsum felis, quis dictum leo posuere sit amet. Integer sagittis, ligula eu ultrices blandit, augue augue cursus quam, eget facilisis lectus elit vel eros. Integer pulvinar ante et tortor venenatis, ut lacinia ligula hendrerit."
  },
  {
    question: "Pellentesque a leo porttitor?",
    answer:
      "Nec bibendum elit lacus non sapien. Etiam at tincidunt metus, ac pretium arcu. Aliquam sodales, urna scelerisque pellentesque tempor, massa tortor fringilla augue, eu pretium lorem odio dignissim elit. Maecenas placerat ipsum ac tellus facilisis aliquam. Pellentesque a leo porttitor, molestie magna a, varius odio. Curabitur et lorem lobortis, aliquet ipsum a, molestie ante. Sed interdum id odio in mattis. Donec eget lectus purus. Mauris molestie ipsum felis, quis dictum leo posuere sit amet. Integer sagittis, ligula eu ultrices blandit, augue augue cursus quam, eget facilisis lectus elit vel eros. Integer pulvinar ante et tortor venenatis, ut lacinia ligula hendrerit."
  },
  {
    question: "Integer pulvinar ante et tortor?",
    answer:
      "Curabitur et lorem lobortis, aliquet ipsum a, molestie ante. Sed interdum id odio in mattis. Donec eget lectus purus. Mauris molestie ipsum felis, quis dictum leo posuere sit amet. Integer sagittis, ligula eu ultrices blandit, augue augue cursus quam, eget facilisis lectus elit vel eros. Integer pulvinar ante et tortor venenatis, ut lacinia ligula hendrerit."
  }
];

const refs = {};

const getTop = elem => {
  const box = elem.getBoundingClientRect();
  const body = document.body;
  const docEl = document.documentElement;
  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const clientTop = docEl.clientTop || body.clientTop || 0;
  const top = box.top + scrollTop - clientTop;
  return Math.round(top) - 10;
};

class ContentPageFaq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: faq.reduce((aggr, item, idx) => ({ ...aggr, [idx]: false }), {})
    };
    // faq.map((item, index) => (this.state.visible[index] = false));
  }

  toggleVisible(index) {
    const { state } = this;
    if (!state.visible[index]) {
      Object.keys(state.visible).map(item => (state.visible[item] = false));
    }
    state.visible[index] = !state.visible[index];
    this.setState(state);
    setTimeout(() => {
      if (window) window.scrollTo(0, getTop(refs[index]));
    }, 0);
  }

  render() {
    const lang = getLocalization().language;
    const contactPath = routes.filter(item => item.type === "contact")[0]
      .labels[lang];
    return (
      <ContentPage page={this.props.match.path}>
        <div className="contentPageFAQ">
          <h1>{getLabel("frequently.asked.questions")}</h1>
          <div className="content">
            <div className="left">
              <div>{getLabel("still.cant.find.answer")}</div>
              <Link to={contactPath}>{getLabel("contact.us")}</Link>
            </div>
            <div className="right">
              {faq.map(({ question, answer }, index) => (
                <div key={index}>
                  <div
                    ref={r => {
                      refs[index] = r;
                    }}
                    className={cx("question", {
                      visible: this.state.visible[index]
                    })}
                    onClick={this.toggleVisible.bind(this, index)}
                  >
                    {index + 1}. {question}
                  </div>
                  {this.state.visible[index] && (
                    <div className="answer">{answer}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </ContentPage>
    );
  }
}

export default ContentPageFaq;
