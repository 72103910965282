import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleRight,
  faAngleDown,
  faAngleUp
} from "@fortawesome/free-solid-svg-icons";
import {
  formatDate,
  getLabel,
  getTravellersText
} from "../../../../utils/functions";
import Searchbox from "../../../Searchbox/Flights/index.jsx";
import cx from "classnames";
require("./style.scss");

class SearchSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchbox: document.body.clientWidth > 992
    };
    this.onToggleSearchbox = this.onToggleSearchbox.bind(this);
  }

  onToggleSearchbox() {
    this.setState({ isSearchbox: !this.state.isSearchbox });
  }

  render() {
    const { isSearchbox } = this.state;
    const { searchbox } = this.props;
    if (!Object.keys(searchbox).length) return <div />;
    return (
      <div className={cx("searchSummary__wrapper", { isSearchbox })}>
        <div className={cx("searchSummary__view", { isSearchbox })}>
          <div
            className={cx("searchSummary__toggle", { isSearchbox })}
            onClick={this.onToggleSearchbox}
          >
            <span className="text">{getLabel("show.searchbox")} </span>
            <FontAwesomeIcon className="down" icon={faAngleDown} />
          </div>
          <div className="airports">
            <span className="line">{searchbox.departure.name}</span>
            <FontAwesomeIcon className="icon" icon={faAngleRight} />
            <span className="line">{searchbox.destination.name}</span>
          </div>
          <div className="dates">
            <strong className="text">{getLabel("departure")}: </strong>
            {formatDate(searchbox.departureDate, "ddd, D/M/YYYY")}
            {!searchbox.isOneway && (
              <>
                <span>
                  {" "}
                  - <strong className="text">{getLabel("return")}: </strong>
                </span>
                {formatDate(searchbox.returnDate, "ddd, D/M/YYYY")}
              </>
            )}
            ,{" "}
            <span className="line">
              {getTravellersText(searchbox.travellers)},
            </span>{" "}
            {getLabel(`class.${searchbox.cabinClass.code}`)}
          </div>
        </div>
        <div className={cx("searchSummary__form", { isSearchbox })}>
          <div
            className={cx("searchSummary__toggle", { isSearchbox })}
            onClick={this.onToggleSearchbox}
          >
            {getLabel("hide.searchbox")} <FontAwesomeIcon icon={faAngleUp} />
          </div>
          <Searchbox className="searchboxResults" />
        </div>
      </div>
    );
  }
}

export default connect(state => {
  const search = state.flights.searches[state.flights.activeSearchKey] || {
    criteria: {},
    responsesCounter: 0
  };
  return {
    searchbox: search.criteria,
    counter: search.responsesCounter
  };
})(SearchSummary);
