import React from "react";
import Loader from "../../../_commons/Loader";
import { getLabel } from "../../../../utils/functions";
require("./style.scss");

export default ({ title }) => (
  <div className="ResultsLoader">
    <div>
      <div className="title">{title}</div>
      <div className="subtitle">
        {getLabel("searching.flights.loader.text")}
      </div>
    </div>
    <Loader />
  </div>
);
