import React, { Fragment } from "react";
import { connect } from "react-redux";
import RangePicker from "react-daterange-picker";
import moment from "moment";
import {} from "moment-range";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Balloon from "../../../_commons/Balloon/index.jsx";
import {
  toggle,
  datepickerDateSelected,
  validateFlightSearchbox
} from "../../../../redux/actions";
import { getLabel, getLocalization } from "../../../../utils/functions";
import OnewaySwitch from "../../Flights/OnewaySwitch";
import cx from "classnames";
require("./style.scss");
require('moment/locale/nl');

const format = "DD/MM/YYYY";
const refs = {
  balloon: null,
  dep: null,
  ret: null
};

class DatePicker extends React.Component {
  
  componentDidMount() {
    console.log(this.props);
    const {start, onValidate} = this.props;
    const [d1, m1, y1] = start.split('/');
    const [y, m, d] = new Date().toISOString().substr(0, 10).split('-');
    if (`${y1}/${m1}/${d1}` < `${y}/${m}/${d}`) {
      onValidate({ dates: true });
    }
  }

  onSelect(value, states) {
    const { dateSelected, isOneway, onValidate } = this.props;
    if (isOneway) {
      dateSelected("departure", value.format(format));
    } else {
      dateSelected("departure", value.start.format(format));
      dateSelected("return", value.end.format(format));
    }
    setTimeout(this.toggleWithScroll, 300);
    onValidate({ dates: false });
  }

  toggleWithScroll = () => {
    if (refs.balloon && refs.balloon.scrollTop) refs.balloon.scrollTop();
    this.props.toggle();
  };

  render() {
    const { isOneway, isVisible, start, end, validation } = this.props;
    const depValue = start;
    const retValue = end;
    let value = null;
    if (isOneway) {
      if (start) value = moment(start, format);
    } else {
      if (start && end)
        value = moment.range(moment(start, format), moment(end, format));
    }
    return (
      <div
        className={cx("datepicker", {
          visible: isVisible,
          invalid: !!validation
        })}
        onClick={event => event.stopPropagation()}
      >
        <div className="values" onClick={this.toggleWithScroll}>
          {depValue}
          {isOneway ? null : retValue ? (
            <Fragment> - {retValue}</Fragment>
          ) : null}
          <FontAwesomeIcon icon={faCalendar} className="icon" />
        </div>
        <Balloon
          ref={r => {
            refs.balloon = r;
          }}
        >
          <div>
            <OnewaySwitch />
            <RangePicker
              numberOfCalendars={3}
              selectionType={isOneway ? "single" : "range"}
              minimumDate={new Date()}
              maximumDate={
                new Date(new Date().setYear(new Date().getFullYear() + 1))
              }
              onSelect={this.onSelect.bind(this)}
              singleDateRange={true}
              value={value}
              locale={moment.locale(getLocalization().language.toLowerCase())}
            />
            <div className="close" onClick={this.toggleWithScroll}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        </Balloon>
        {validation && (
          <div className="validation">
            <div>{getLabel("wrong.dates")}</div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  state => ({
    isOneway: state.flights.searchbox.isOneway,
    isVisible: state.toggles.flightsSearchboxDatepicker,
    start: state.flights.searchbox.departureDate,
    end: state.flights.searchbox.returnDate,
    validation: state.flights.searchboxValidation.dates
  }),
  dispatch => ({
    toggle: event => {
      if (event) event.stopPropagation();
      dispatch(toggle("flightsSearchboxDatepicker"));
    },
    dateSelected: (field, date) =>
      dispatch(datepickerDateSelected(field, date)),
    onValidate: validations => dispatch(validateFlightSearchbox(validations))
  })
)(DatePicker);
