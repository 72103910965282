import React from "react";
import { connect } from "react-redux";
import Autosuggest from "react-autosuggest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";
import { api, getLabel } from "../../../../utils/functions";
import Loader from "../../../_commons/Loader";
// import Checkbox from "../../_commons/Checkbox/index.jsx";
import {
  autocompleteAirportValueChanged,
  autocompleteAirportSelected,
  autocompleteAirportSwitch,
  autocompleteAirportsNearby,
  validateFlightSearchbox
} from "../../../../redux/actions";
import cx from "classnames";

require("./style.scss");

// let suggestions = [];

const renderSuggestion = suggestion => (
  <>
    <div className="airport">
      <div className="name">
        {suggestion.name} {suggestion.name2}
      </div>
      <div className="code">{suggestion.code}</div>
    </div>
    <div className="country">{suggestion.country}</div>
  </>
);

const renderSectionTitle = () => {};

const getSuggestionValue = suggestion => {
  return suggestion.name + " (" + suggestion.code + ")";
};

const getSectionSuggestions = section => {
  return section.airports;
};

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airports: [],
      loading: false
    };
  }

  shouldRenderSuggestions(value) {
    return true;
  }

  onSuggestionSelected = (_, { suggestion, suggestionValue }) => {
    const { direction, onSelect, onValidate } = this.props;
    if (suggestion && suggestionValue) {
      onSelect(suggestionValue, suggestion);
      const validations = {};
      validations[direction] = "";
      onValidate(validations);
    }
  };

  setAirports(airports) {
    const { state } = this;
    state.airports = airports;
    this.setState(state);
  }

  onSuggestionsUpdateRequested({ value }) {
    if (value.length >= 3) {
      this.setState({ loading: true });
      api("/search/autocomplete", { phrase: value }).then(response => {
        this.setAirports(response);
        this.setState({ loading: false });
      });
    } else {
      this.setAirports([]);
    }
  }

  handleSwitch(departure, destination, onSwitch) {
    const tmpDeparture = Object.assign({}, departure);
    onSwitch("departure", destination);
    onSwitch("destination", tmpDeparture);
  }

  autofill(event) {
    if (this.state.airports.length > 0) this.setAutofill();
    setTimeout(() => {
      this.setAutofill();
    }, 500);
  }

  setAutofill() {
    const {
      direction,
      departure,
      destination
      // onSelect,
      // onValidate
    } = this.props;
    const airport = direction === "departure" ? departure : destination;
    const code = airport.value.toUpperCase();
    if (code.length !== 3) return;
    if (this.state.airports.length > 0) {
      this.state.airports.forEach(item => {
        if (item.code === code) {
          this.onSuggestionSelected(null, {
            suggestion: item,
            suggestionValue: getSuggestionValue(item)
          });
        }
      });
    }
  }

  render() {
    const {
      direction,
      departure,
      destination,
      validation,
      onValueChange,
      // onSelect,
      onSwitch
      // onNearby
      // onValidate
    } = this.props;
    const isDeparture = direction === "departure";
    // const isDestination = direction === "destination";
    const airport = isDeparture ? departure : destination;
    const inputProps = {
      placeholder: getLabel(
        `${isDeparture ? "departure" : "destination"}.airport`
      ),
      value: airport.value,
      onChange: (event, { newValue }) => {
        onValueChange(newValue);
      },
      onFocus: event => {
        const input = event.target;
        input.setSelectionRange(0, input.value.length);
      },
      onBlur: this.autofill.bind(this)
    };
    const { loading } = this.state;
    return (
      <div
        className={cx("autocomplete", {
          withSwitch: isDeparture,
          invalid: !!validation,
          loading
        })}
      >
        <Autosuggest
          multiSection={true}
          focusInputOnSuggestionClick={false}
          inputProps={inputProps}
          suggestions={[{ title: "", airports: this.state.airports }]}
          renderSuggestion={renderSuggestion}
          renderSectionTitle={renderSectionTitle}
          shouldRenderSuggestions={this.shouldRenderSuggestions.bind(this)}
          // onSuggestionsUpdateRequested={this.onSuggestionsUpdateRequested.bind(
          //   this
          // )}
          onSuggestionsFetchRequested={this.onSuggestionsUpdateRequested.bind(
            this
          )}
          onSuggestionSelected={this.onSuggestionSelected}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={getSuggestionValue}
          getSectionSuggestions={getSectionSuggestions}
        />
        {loading && (
          <div className="switch">
            <Loader small gray />
          </div>
        )}
        {!loading && isDeparture && (
          <div
            className="switch"
            onClick={this.handleSwitch.bind(
              this,
              departure,
              destination,
              onSwitch
            )}
          >
            <FontAwesomeIcon icon={faExchangeAlt} />
            {/*<img
              src={require("../../../../static/images/icons/switch.png")}
              onClick={this.handleSwitch.bind(
                this,
                departure,
                destination,
                onSwitch
              )}
              alt="Switch"
              />*/}
          </div>
        )}
        {validation !== "" && (
          <div className="validation">
            {validation === "empty" && (
              <div>
                {getLabel(
                  `empty.${isDeparture ? "departure" : "destination"}.field`
                )}
              </div>
            )}
            {validation === "wrong" && (
              <div>
                {getLabel(
                  `wrong.${isDeparture ? "departure" : "destination"}.field`
                )}
              </div>
            )}
            {validation === "same" && (
              <div>{getLabel("destination.airport.must.be.different")}</div>
            )}
          </div>
        )}
        {/*<Checkbox onChange={onNearby} checked={airport.isNearby}>
          {getLabel("add.nearby.airports")}
            </Checkbox>*/}
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    departure: state.flights.searchbox.departure,
    destination: state.flights.searchbox.destination,
    validation: state.flights.searchboxValidation[ownProps.direction]
  }),
  (dispatch, ownProps) => ({
    onValueChange: value =>
      dispatch(autocompleteAirportValueChanged(ownProps.direction, value)),
    onSelect: (value, airport) =>
      dispatch(autocompleteAirportSelected(ownProps.direction, value, airport)),
    onSwitch: (direction, airport) =>
      dispatch(autocompleteAirportSwitch(direction, airport)),
    onNearby: isNearby =>
      dispatch(autocompleteAirportsNearby(ownProps.direction, isNearby)),
    onValidate: validations => dispatch(validateFlightSearchbox(validations))
  })
)(Autocomplete);
