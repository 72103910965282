import React from "react";
import config from "../../../../config";
require("./style.scss");

const { brand } = config;

export default () => (
  <div className="redirectHeaderWrap">
    <img
      className="logo"
      src={require("../../../../static/images/icons/logotravelio.jpg")}
      alt={brand}
    />
  </div>
);
