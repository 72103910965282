import initState from "./init.js";

const init = initState.searchbox;

const isFilled = (state = false, action) => {
  switch (action.type) {
    case "SET_FLIGHTS_SEARCHBOX_FILLED":
      return action.isFilled;
    default:
      return state;
  }
};

const isOneway = (state = init.isOneway, action) => {
  switch (action.type) {
    case "UPDATE_SEARCHBOX_ONEWAY":
      return action.isOneway;
    default:
      return state;
  }
};

const airport = (state, action, direction) => {
  if (action.direction !== direction) return state;
  switch (action.type) {
    case "AUTOCOMPLETE_AIRPORT_VALUE_CHANGED":
      return Object.assign({}, state, {
        code: "",
        name: "",
        country: "",
        value: action.value
      });
    case "AUTOCOMPLETE_AIRPORT_SELECTED":
      return Object.assign({}, state, {
        code: action.airport.code,
        name: action.airport.name,
        country: action.airport.country,
        value: action.value
      });
    case "AUTOCOMPLETE_AIRPORT_SWITCH":
      return action.airport;
    case "AUTOCOMPLETE_AIRPORTS_NEARBY":
      return Object.assign({}, state, { isNearby: action.isNearby });
    default:
      return state;
  }
};

const airportDeparture = (state = init.departure, action) =>
  airport(state, action, "departure");

const airportDestination = (state = init.destination, action) =>
  airport(state, action, "destination");

const dateDeparture = (state = init.departureDate, action) =>
  date(state, action, "departure");

const dateReturn = (state = init.returnDate, action) =>
  date(state, action, "return");

const date = (state, action, field) => {
  if (action.field !== field) return state;
  switch (action.type) {
    case "DATEPICKER_DATE_SELECTED":
      return action.date;
    default:
      return state;
  }
};

const travellers = (state = init.travellers, action) => {
  switch (action.type) {
    case "UPDATE_TRAVELLERS":
      return action.travellers;
    case "CREMENT_TRAVELLER":
      const type = action.traveller;
      const nextValue = state[type] + action.value;
      const min = type === "adt" ? 1 : 0;
      if (
        nextValue < min ||
        state.adt + state.ch + state.inf + action.value > 9
      )
        return state;
      const newState = Object.assign({}, state);
      newState[type] += action.value;
      if (newState.inf > newState.adt) newState.inf = newState.adt;
      return newState;
    default:
      return state;
  }
};

const cabinClass = (state = init.cabinClass, action) => {
  switch (action.type) {
    case "UPDATE_CABINCLASS":
      return action.cabinClass;
    default:
      return state;
  }
};

const competitors = (state = init.competitors, action) => {
  switch (action.type) {
    case "TOGGLE_SEARCHBOX_COMPETITOR":
      if (action.isChecked) {
        return [...state, action.competitor];
      }
      const index = state.indexOf(action.competitor);
      return [...state.slice(0, index), ...state.slice(index + 1)];
    default:
      return state;
  }
};

const validation = (
  state = { departure: false, destination: false, dates: false },
  action
) => {
  switch (action.type) {
    case "VALIDATE_SEARCHBOX":
      const newState = Object.assign({}, state);
      if (typeof action.validations.departure === "string")
        newState.departure = action.validations.departure;
      if (typeof action.validations.destination === "string")
        newState.destination = action.validations.destination;
      if (typeof action.validations.dates === "boolean")
        newState.dates = action.validations.dates;
      return newState;
    default:
      return state;
  }
};

export default {
  isFilled,
  isOneway,
  competitors,
  airport: {
    departure: airportDeparture,
    destination: airportDestination
  },
  date: {
    departure: dateDeparture,
    return: dateReturn
  },
  travellers,
  cabinClass,
  validation
};
