import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import SearchSummary from "../../Flights/SearchSummary/index.jsx";
import FlightResults from "../../Flights/FlightResults/index.jsx";
import FilterPanel from "../../Flights/FilterPanel/index.jsx";
import PagingBar from "../PagingBar/index.jsx";
import ResultsLoader from "../ResultsLoader";
import ResultsTabs from "../ResultsTabs";
import { renderMeta } from "../../../../utils/functions";
import { getFilteredResults } from "../../Flights/functions";
import Banner from '../Banner';
import init from "../../../../redux/store/flights/init";
require("./style.scss");

const ResultsPanel = ({
  results,
  criteria,
  responsesCounter,
  filterVisible,
  filter,
  sorting
}) => {
  const { departure = { code: "" }, destination = { code: "" } } = criteria;
  const filteredResults = getFilteredResults(results, filter, sorting);
  return (
    <div className="resultsPanel">
      {renderMeta("results.flights", {
        depAirport: departure.code,
        desAirport: destination.code
      })}
      <div className="resultsPanel__searchSummary">
        <div className="resultsPanel__searchSummary__box">
          <SearchSummary />
        </div>
      </div>
      <ResultsTabs />
      <div className="resultsPanel__results">
        <div className={cx("resultsPanel__results__box", {
          withBanner: results.length > 0
        })}>
          {responsesCounter === 0 ? (
            <ResultsLoader title={`${departure.code} - ${destination.code}`} />
          ) : (
            <>
              <div
                className={cx("resultsPanel__results__box--filter", {
                  filterVisible,
                  noResults: results.length === 0
                })}
              >
                <FilterPanel
                  counts={`${filteredResults.length}/${results.length}`}
                />
              </div>
              <div className="resultsPanel__results__box--content">
                <FlightResults filteredResults={filteredResults} />
                <PagingBar />
              </div>
              <Banner />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect(state => {
  const search = state.flights.searches[state.flights.activeSearchKey] || {
    criteria: {},
    results: [],
    responsesCounter: 0,
    sorting: init.sorting,
    filter: {
      values: init.filter,
      edges: init.edges
    }
  };
  return {
    criteria: search.criteria,
    results: search.results,
    responsesCounter: search.responsesCounter,
    filterVisible: state.flights.filterVisible,
    sorting: search.sorting,
    filter: search.filter.values
  };
})(ResultsPanel);
