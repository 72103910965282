import init from './init.js'

const toggles = (state = init.toggle, action) => {
    switch (action.type) {
        case 'TOGGLE':
            const newState = Object.assign({}, init.toggle)
            if (action.what) {
                if (action.item) {
                    newState[action.what] = {}
                    newState[action.what][action.item] = !state[action.what][action.item]
                } else {
                    newState[action.what] = !state[action.what]
                }
            }
            return newState
        default:
            return state
    }
}

const getMax = (state = 0, action) => {
    switch (action.type) {
        case 'UPDATE_FLIGHTS_FILTER_EDGES_PRICE':
            return Math.max(state, action.value)
        default:
            return state
    }
}

export default {
    getMax,
    toggles,
}
