import React from 'react'
require('./style.scss')

class PanelMain extends React.Component {

    render() {
        return (<div className="panelMain">{this.props.children}</div>)
    }

}

export default PanelMain
