import { createStore, combineReducers, compose } from "redux";
import { routerReducer } from "react-router-redux";
import commons from "./commons.js";
import flights from "./flights/index.js";

export default createStore(
  combineReducers({
    routing: routerReducer,
    flights,
    toggles: commons.toggles
  }),
  {},
  compose(global.devToolsExtension ? global.devToolsExtension() : f => f)
);
